// ニュース PC

.news-page {
	position: relative;
	z-index: $zIndex2;
	padding-top: pcw(102);
	margin-bottom: -1px;
	overflow: hidden;

	.news-categories {
		display: flex;
		justify-content: flex-end;
		padding-bottom: 16px;
		border-bottom: 1px solid $gray4;
		margin-bottom: 64px;
		ul {
			display: flex;
		}
		li {
			margin-left: 22px;
			&:first-child {
				margin-left: 0;
			}
		}
		a {
			position: relative;
			color: $gray3;
			font-size: 15px;
			line-height: 30px;
			padding-bottom: 2px;

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: -3px;
				width: 100%;
				height: 1px;
				background: $ml_blue;
				transform: scaleX(0);
				transform-origin: right top;
				transition: transform 0.8s $easeOutExpo;
			}

			&.current {
				color: $ml_blue;
				&::after {
					transform: scaleX(1);
				}
			}
			&:hover {
				color: $ml_blue;
				&::after {
					transform: scaleX(1);
					transform-origin: left top;
				}
			}
		}
	}
}

/**
 * ニューストップ
 */ 
.news-top {
	padding-left: pcw(200);
	margin-bottom: 170px;
	@include setPcContents();

	@include pcNewsList();

	.news-item {
		a {
			display: flex;
			align-items: flex-start;
		}
		.meta {
			padding-top: 5px;
			margin-bottom: 0;
			margin-right: 20px;
		}
		.title {
			flex: 1;
		}
	}

	.more {
		margin-top: 50px;
		a {
			margin: auto;
			position: relative;
			display: block;
			width: 40px;
			height: 40px;
			&::before,
			&::after {
				position: absolute;
				content: "";
				width: 100%;
				height: 1px;
				top: 50%;
				background: $black;
			}
			&::after {
				transform: rotate(90deg);
			}
		}
	}
	.noData{
		padding-top: 40px;
		padding-bottom: 50px;
		text-align: center;
		font-size: 15px;
	}
}


/**
 * ニュース詳細
 */ 
.news-detail {
	padding-left: pcw(200);
	margin-bottom: 170px;
	@include setPcContents();
	.news-article {
		.meta {
			display: flex;
			align-items: center;
			margin-bottom: 25px;
			
			.tag {
				color: $black;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.04em;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 72px;
				height: 22px;
				margin-right: 12px;
				background: $gray4;
			}

			.date {
				color: $gray4;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.02em;
			}
			.tag {
				color: $white;
			}
			.date {
				color: $black;
			}
			a {
				&::before {
					background: $black;
				}
				&::after {
					border-left-color: $black;
				}
			}
			@include tagColor;
		}
		.post-title {
			font-size: 32px;
			line-height: 58px;
			margin-bottom: 30px;
		}
		.contents {
			margin-bottom: 60px;
			word-wrap: break-word;
			font-size: 14px;
			line-height: 1.8;
			
			// エディータコンテンツ
			
			p {
				margin-bottom: 2em;
			}
			strong{
				font-weight: bold;
			}
			em{
				font-style: italic;
			}
			img{
				max-width: 100%;
				height: auto;
			}
			
			ul{
				margin-bottom: 2em;
				li{
					padding-left: 1em;
					margin-bottom: 8px;
					line-height: 1.6;
					&::before{
						content: "・";
						position: relative;
						margin-left: -1em;
					}
				}
			}
			ol{
				margin-bottom: 2em;
				padding-left: 1.3em;
				li{
					margin-bottom: 8px;
					list-style-type: decimal;
					line-height: 1.6;
				}
			}
			table{
				margin-bottom: 2em;
				border: 1px solid #CCC;
				th{
					padding: 5px;
					background: #CCC;
					font-weight: bold;
				}	
				td{
					border: 1px solid #CCC;
					padding: 5px;
				}
				p{
					margin-bottom: 0px;
				}
			}
			
			hr{
				margin: 3em 0;
			}
			
			a {
				color: $ml_sky;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}

		.image {
			img {
				max-width: 100%;
				width: auto;
				height: auto;
			}
			margin-bottom: 48px;
		}

		.back-button {
			margin-top: 65px;
			.mod-basic-btn {
				a {
					width: 320px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
	.image-gallery {
		.image {
			margin-bottom: 0;
		}
		@include pcImageGallery();
		.main-images {
			margin-bottom: 20px;
		}
	}
}