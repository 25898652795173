// 概要レイアウト SP
.mod-overview {
	position: relative;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 150px;
	padding-top: 42px;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		height: 1px;
		width: 100%;
		background: $gray4;
	}
	.data {
		&_list {
			display: flex;
			margin-bottom: 28px;
		}
		&_title {
			font-weight: 700;
			width: 104px;
			padding-right: 10px;
			span{
				display: block;
				font-size: 85%;
				font-weight: normal;
				line-height: 1.5;
				
			}
		}
		&_title,
		&_summary {
			font-size: 13px;
			line-height: 24px;
		}
		&_summary {
			flex: 1;
			.indent {
				padding-left: 14px;
			}
			.num-indent {
				text-indent: -14px;
				padding-left: 14px;
			}
			a {
				color: $ml_sky;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			ul{
				li{
					&::before{
						content: "・";
						position: absolute;
						margin-left: -1em;
					}
					padding-left: 0.7em;
					padding-bottom: 6px;
					line-height: 1.6;
				}
			}
		}
	}
	.image {
		margin-top: 45px;
		margin-left: -35px;
		margin-right: -35px;
		width: auto;
		img {
			width: 100%;
			height: auto;
		}
	}
}