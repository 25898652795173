@mixin pc{
	@media (min-width: $pc){
		@content
	}
};

@mixin sp{
	@media (max-width: $sp){
		@content
	}
};


/**
 * PCのデザイン1280pxに対する可変幅
 */ 
@function pcw($px, $viewport: $pcDesignWidth) {
	@return getWidth($px, $viewport);
}

/**
 * SPのデザイン750pxに対する可変幅
 */ 
@function spw($px, $viewport: $spDesignWidth) {
	@return getWidth($px , $viewport);
}

/**
 * 画面に対しての幅を計算
 */
@function getWidth($px, $viewport: $pcDesignWidth) {
	@return floor(($px / $viewport) * 10000vw) / 100;
}


/**
 * PCコンテンツ設定
 */
@mixin setPcContents() {
	width: pcw(936);
	margin-left: auto;
	margin-right: auto;

	@media screen and (max-width: $pcDesignWidth2 * 1px) {
		width: pcw(936, $pcDesignWidth2);
	}
}

@mixin pcSmall {
	@media (max-width: $pcDesignWidth2 * 1px){
		@content
	}
};


/**
 * SPコンテンツ設定
 */
@mixin setSpContents() {
	padding-left: 36px;
	padding-right: 36px;

	@media (max-width: $spDesignWidth2 * 1px){
		padding-left: 20px;
		padding-right: 20px;
	}
}

@mixin spSmall {
	@media (max-width: $spDesignWidth2 * 1px){
		@content
	}
};



/**
 * PCフォント設定
 */
@mixin setPcFont($size, $lh) {
	font-size: pcw($size);
	line-height: pcw($lh);

	@media screen and (max-width: ($pcDesignWidth * 1px)) {
		font-size: $size * 1px;
		line-height: $lh * 1px;
	}

	@include setPcLargeFont($size, $lh);
}

@mixin setPcLargeFont($size, $lh) {
	@media screen and (min-width: ($pcDesignWidth3 * 1px)) {
		font-size: $size * 1.32px;
		line-height: $lh * 1.32px;
	}
}


/**
 * SPフォント設定
 */
@mixin setSpFont($size, $lh) {
	font-size: spw($size);
	line-height: spw($lh);
}

/**
 * 画像ホバー時拡大
 */
@mixin imgHover() {
	overflow: hidden;
	img {
		transition: transform 1.0s $easeOutExpo;
	}
	&:hover {
		img {
			transform: scale(1.05, 1.05);
			transition: transform 0.5s $easeOutExpo;
		}
	}
}


/**
 * ニュースリスト
 */
@mixin tagColor {
	&.event {
		.tag {
			background: #005B98;
		}
	}
	&.news {
		.tag {
			background: #34426A;
		}
	}
	&.recruit {
		.tag {
			background: #1E1B19;
		}
	}
	&.csr {
		.tag {
			background: #396082;
		}
	}
	&.seminar {
		.tag {
			background: #393982;
		}
	}
	&.info {
		.tag {
			background: #356A78;
		}
	}
	&.pr {
		.tag {
			background: #473578;
		}
	}
}
@mixin pcNewsList {
	.news-item {
		.meta {
			display: flex;
			align-items: center;
			margin-bottom: 7px;
		}
		.tag {
			color: $black;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.04em;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 72px;
			height: 22px;
			margin-right: 12px;
			background: $gray4;
		}

		.date {
			color: $gray4;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.02em;
		}

		.title {
			color: $gray4;
			font-size: 15px;
			line-height: 30px;
			padding-right: 15px;
		}
		a {
			display: block;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: -3px;
				width: 100%;
				height: 1px;
				background: $gray4;
				transform: scaleX(0);
				transform-origin: right top;
				transition: transform 0.8s $easeOutExpo;
			}

			&::after {
				content: "";
				position: absolute;
				right: -2px;
				bottom: -2px;	
				border: 6px solid transparent;
				border-left-color: $gray4;
				transform: rotate(45deg);
			}

			&:hover {
				&::before {
					transform: scaleX(1);
					transform-origin: left top;
				}
			}
		}
	}
	
	.news-item {
		margin-bottom: 33px;
		.tag {
			color: $white;
		}
		.date,
		.title {
			color: $black;
		}
		a {
			&::before {
				background: $black;
			}
			&::after {
				border-left-color: $black;
			}
		}
		@include tagColor;
	}
}

@mixin spNewsList {
	.news-list {
		.meta {
			display: flex;
			align-items: center;
			margin-bottom: 7px;
		}
		.tag {
			color: $white;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.04em;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 72px;
			height: 22px;
			margin-right: 12px;
		}

		.date {
			font-size: 12px;
			line-height: 20px;
			letter-spacing: 0.02em;
		}

		.title {
			font-size: 15px;
			line-height: 30px;
			padding-right: 15px;
		}

		.news-item {
			margin-bottom: 33px;
			a {
				display: block;
				position: relative;
				&::after {
					content: "";
					position: absolute;
					right: -2px;
					bottom: -2px;

					border: 6px solid transparent;
					border-left-color: $black;
					transform: rotate(45deg);
				}
			}
			@include tagColor;
		}
	}
}

@mixin moviePlay($ratio, $hover: true) {
	.play {
		position: absolute;
		left: 20px;
		top: 0;
		right: 0;
		bottom: 0;
		width: ceil(97px * $ratio);
		height: ceil(109px * $ratio);
		overflow: hidden;
		margin: auto;
		background: url(../img/common/movie_play.png) no-repeat 0 0;
		background-size: auto ceil(109px * $ratio);
	}
	a {
		position: relative;
		display: block;
		z-index: 2;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: ceil(60px * $ratio);
			margin: auto;
			width: ceil(188px * $ratio);
			height: ceil(110px * $ratio);
			background: url(../img/common/movie_play_text.svg) no-repeat 0 0;
			background-size: auto ceil(110px * $ratio);
		}
		
		@if $hover {
			&:hover {
				.play {
					animation: play_movie_hover 0.3s  steps(12) forwards;
				}
			}
			.play {
				animation: play_movie_blur 0.3s  steps(12) forwards;
			}
		}
	}
}

@keyframes play_movie_hover {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: -1164px 0;
	}
}

@keyframes play_movie_blur {
	0% {
		background-position: -1164px 0;
	}
	100% {
		background-position: 0 0;
	}
}

/**
 * 画像ギャラリー
 * - 物件詳細 > ロケーション
 * - ニュース詳細 > 画像ギャラリー
 */
@mixin pcImageGallery {
	.main-images {
		position: relative;
		margin-bottom: 16px;
		.image {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			z-index: 1;
			width: 100%;
			aspect-ratio: 2045 / 1414;
			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				border: 1px solid $gray3;
			}
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.current {
			position: relative;
			z-index: 2;
			opacity: 1;
		}
	}
	.thumb-images {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin-left: pcw(178);
		@media screen and (max-width: $pcDesignWidth2 * 1px) {
			margin-left: pcw(90);
		}
		.image {
			margin-left: 12px;
			margin-top: 12px;
			width: calc(16.66% - 12px);
			a {
				position: relative;
				display: block;
				width: 100%;
				aspect-ratio: 2045 / 1414;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					border: 1px solid $gray3;
					transition: 0.6s border $easeOutExpo;
				}
				&:hover {
					&::after {
						border: 1px solid rgba($ml_sky, 0.7);
					}
				}
				&.current {
					&::after {
						border: 1px solid $ml_sky;
					}
				}
			}
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}
@mixin spImageGallery {
	.main-images {
		position: relative;
		margin-bottom: 17px;
		.image {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			z-index: 1;
			width: 100%;
			aspect-ratio: 2045 / 1414;
			&::after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				box-sizing: border-box;
				border: 1px solid $gray3;
			}
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.current {
			position: relative;
			z-index: 2;
			opacity: 1;
		}
	}
	.thumb-images {
		display: flex;
		flex-wrap: wrap;
		.image {
			margin: 7px 3px 0;
			width: calc(25% - 6px);

			a {
				position: relative;
				display: block;
				width: 100%;
				aspect-ratio: 2045 / 1414;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1;
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					border: 1px solid $gray3;
					transition: 0.6s border $easeOutExpo;
				}
				&.current {
					&::after {
						border: 1px solid $ml_sky;
					}
				}
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}
