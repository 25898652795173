// 共通 ビジネスページ下部

.footer-business {
	position: relative;
	z-index: $zIndex2;
}

@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
