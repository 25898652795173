// 概要レイアウト PC
.mod-overview {
	position: relative;
	padding-left: pcw(200);
	padding-top: 55px;
	margin-bottom: 170px;
	@include setPcContents();
	&::before {
		content: "";
		position: absolute;
		left: pcw(200);
		top: 0;
		right: 0;
		height: 1px;
		background: $gray4;
	}
	.data {
		&_list {
			display: flex;
			margin-bottom: 34px;
		}
		&_title {
			font-weight: 700;
			width: 192px;
			padding-right: 20px;
			span{
				display: block;
				font-size: 85%;
				font-weight: normal;
				line-height: 1.3;
				letter-spacing: -0.2px;
			}
		}
		&_title,
		&_summary {
			font-size: 15px;
			line-height: 30px;
		}
		&_summary {
			flex: 1;
			.indent {
				padding-left: 16px;
			}
			.num-indent {
				text-indent: -16px;
				padding-left: 16px;
			}
			a {
				color: $ml_sky;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
			ul{
				li{
					&::before{
						content: "・";
						position: absolute;
						margin-left: -1em;
					}
					padding-left: 0.7em;
					padding-bottom: 6px;
					line-height: 1.6;
				}
			}
		}
	}
	.image {
		margin-top: 60px;
		img {
			width: 100%;
			height: auto;
		}
	}
}