// エラー SP

.error-page {
	overflow: hidden;
	margin-bottom: -1px;
	padding: 60px 0px 80px;
	
	h1{
		@include setPcFont(22, 32);
		font-weight: 700;
		text-align: center;
		width: 85.09vw;
		margin: 0px auto 35px;
	}
}


.error__contents {
	@include setPcContents();

	p{
		font-size: 12px;
		line-height: 1.9;
		margin-bottom: 50px;
		text-align: center;
	}
	div{
		width: 264px;
		margin: 0px auto;
	}
}