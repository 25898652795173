// 共通ヘッダー　SP
body {
	padding-top: 72px;
	&.top-page {
		padding-top: 0;
	}
}

.site-header {
	position: absolute;
	z-index: $zIndex10 - 2;
	left: 0;
	top: 0;
	width: 100%;
	height: 72px;
	background: $white;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	
	&__logo {
		position: absolute;
		left: 16px;
		top: 22px;
		img {
			width: 193px;
		}
	}
	
	&__menu {
		.site-menu {
			display: none;
		}

		.client-menu {
			mix-blend-mode: difference;
			position: absolute;
			top: 94px;
			right: 18px;
			.mod-basic-btn {
				margin-bottom: 10px;
			}
		}
	}
}

.hamburger {
	top: 17px;
	right: 8px;
}

