// 共通 お問い合わせ
.footer-contact {
	background: $black;
	
	&__contents {
		position: relative;
		z-index: 2;
	}

	.footer-contact-canvas {
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		// bottom: -32px;
		width: 100%;
		height: 85%;
		pointer-events: none;
		background: url("/assets/img/common/footer_contact.jpg") no-repeat 100% 0;
		background-size: 60% auto;
	}
}

@include pc {
	@import "./pc";
}

@include sp {
	.footer-contact {
		.footer-contact-canvas {
			background-size: auto 100%;
		}
	}
	@import "./sp";
}
