// 共通タイトル PC

.mod-title {
	&__en {
		color: $black;
		@include setPcFont(13, 26);
		font-weight: 700;
		letter-spacing: 0.02em;
		padding-bottom: 1px;
		border-bottom: 1px solid $black;

		&.white {
			color: $white;
			border-bottom-color: $white;
		}
	}

	&__ja {
		@include setPcFont(44, 80);
		font-weight: 700;
		
		
		&.small {
			@include setPcFont(42, 80);
		}

		&.white {
			color: $white;
		}
	}
}