
// 共通ヘッダー
.header-open {
	overflow: hidden;
}

.hamburger {
	position: fixed;
	z-index: $zIndex10;
	width: 60px;
	height: 40px;
	mix-blend-mode: difference;
	&__link {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		span {
			position: absolute;
			right: 10px;
			height: 1px;
			background: $white;
		}
		.top {
			top: 10px;
			width: 41px;
		}
		.middle {
			top: 20px;
			width: 26px;
		}
		.bottom {
			top: 30px;
			width: 10px;
		}
	}
	.header-close & {
		.top {
			animation: hamburger_close_top 0.6s $easeOutExpo 0s 1 normal forwards;
		}
		.middle {
			animation: hamburger_close_middle 0.6s $easeOutExpo 0s 1 normal forwards;
		}
		.bottom {
			animation: hamburger_close_bottom 0.6s $easeOutExpo 0s 1 normal forwards;
		}
	}
	.header-open & {
		.top {
			animation: hamburger_open_top 0.6s $easeOutExpo 0s 1 normal forwards;
		}
		.middle {
			animation: hamburger_open_middle 0.6s $easeOutExpo 0s 1 normal forwards;
		}
		.bottom {
			animation: hamburger_open_bottom 0.6s $easeOutExpo 0s 1 normal forwards;
		}
	}
}

.sp-site-header {
	position: fixed;
	z-index: $zIndex10 - 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $black;
	opacity: 0;
	transform: scale(1.1);
	pointer-events: none;
	transition: all 0.8s $easeOutExpo;
	.header-open & {
		opacity: 1;
		transform: scale(1);
		pointer-events: inherit;
	}

	.logo {
		height: 73px;
		background: $white;
		padding-left: 16px;
		display: flex;
		align-items: center;
		a {
			display: block;
			height: 24px;
			justify-content: flex-start;
			img {
				width: auto;
				height: 100%;
			}
		}
	}
	.menu-contents {
		&__scroll {
			position: absolute;
			top: 73px;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&__scroll {
			overflow-y: scroll;
		}
		&__inner {
			padding: 65px 35px;
		}
		.client-menu {
			margin-bottom: 55px;
			.mod-basic-btn {
				margin-bottom: 16px;
				a {
					width: 100%;
					height: 56px;
				}
			}
		}
		.site-menu {
			margin-bottom: 65px;
			&__title {
				padding-bottom: 1px;
				border-bottom: 1px solid $white;
				margin-bottom: 10px;
				a, .no-link {
					color: $white;
					font-size: 18px;
					line-height: 36px;
					font-weight: 700;
				}
			}
			&__list {
				padding-left: 10px;
			}
			&__link {
				color: $white;
				font-size: 14px;
				line-height: 34px;
			}
		}
		.category-menu {
			margin-bottom: 20px;
		}
	}
	.contact {
		&__item {
			margin-bottom: 5px;
		}
		&__link,
		&__no-link {
			position: relative;
			display: flex;
			width: 100%;
			height: 90px;
			justify-content: center;
			align-items: center;
			background: $white;
			box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
		}
		&__link { 
			&::after {
				content: "";
				position: absolute;
				right: -2px;
				bottom: -2px;

				border: 6px solid transparent;
				border-left-color: $black;
				transform: rotate(45deg);
			}
		}
		
		&__link,
		&__no-link {
			.inner {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
			}
			.icon {
				display: block;
				width: 28px;
				height: 28px;
				svg {
					width: 28px;
					height: 28px;
				}
			}
			.title {
				font-size: 12px;
				line-height: 24px;
				font-weight: 700;
			}

			.customer-service {
				.title {
					font-size: 11px;
					line-height: 20px;
					font-weight: 700;
					margin-bottom: -10px;
				}

				.tel {
					font-size: 24px;
					line-height: 40px;
					margin-bottom: -5px;
				}

				.notes {
					text-align: center;
					font-size: 10px;
					line-height: 18px;
					width: 100%;
				}
			}
		}
	}
}

// 閉じるボタンに変形
@keyframes hamburger_open_top {
	0% {
		top: 10px;
		width: 41px;
		transform: rotate(0);
	}
	50% {
		top: 20px;
		width: 24px;
		transform: rotate(0);
	}
	100% {
		top: 20px;
		width: 24px;
		transform: rotate(45deg);
	}
}

@keyframes hamburger_open_middle {
	0% {
		top: 20px;
		width: 26px;
		opacity: 1;
	}
	50% {
		top: 20px;
		width: 26px;
		opacity: 0;
	}
	100% {
		top: 20px;
		width: 26px;
		opacity: 0;
	}
}

@keyframes hamburger_open_bottom {
	0% {
		top: 30px;
		width: 10px;
		transform: rotate(0);
	}
	50% {
		top: 20px;
		width: 24px;
		transform: rotate(0);
	}
	100% {
		top: 20px;
		width: 24px;
		transform: rotate(-45deg);
	}
}

// ハンバーグに戻る
@keyframes hamburger_close_top {
	0% {
		top: 20px;
		width: 24px;
		transform: rotate(45deg);
	}
	50% {
		top: 20px;
		width: 24px;
		transform: rotate(0);
	}
	100% {
		top: 10px;
		width: 41px;
		transform: rotate(0);
	}
}

@keyframes hamburger_close_middle {
	0% {
		top: 20px;
		width: 26px;
		opacity: 0;
	}
	50% {
		top: 20px;
		width: 26px;
		opacity: 0;
	}
	100% {
		top: 20px;
		width: 26px;
		opacity: 1;
	}
}


@keyframes hamburger_close_bottom {
	0% {
		top: 20px;
		width: 24px;
		transform: rotate(-45deg);
	}
	50% {
		top: 20px;
		width: 24px;
		transform: rotate(0);
	}
	100% {
		top: 30px;
		width: 10px;
		transform: rotate(0);
	}
}

@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
