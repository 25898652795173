// 共通ボタン

// #js-bg-canvas {
// 	position: fixed;
// 	// z-index: 1000;
// 	z-index: $zIndexCanvas;
// 	left: 0;
// 	top: 0;
// 	width: 100%;
// 	height: 100vh;
// 	pointer-events: none;
// }

.bg-video {
	position: fixed;
	z-index: $zIndexVideo;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	pointer-events: none;
	overflow: hidden;
	background: $black;
	video {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}