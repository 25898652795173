// ご契約中のオーナー様 PC
.for-owner-page {
	position: relative;
	z-index: $zIndex2;
	padding-top: pcw(102);
	overflow: hidden;
	margin-bottom: -1px;
}

.for-owner__contents {
	@include setPcContents();
	margin-bottom: 175px;
	
	.block{
		padding-left: pcw(200);
		margin-bottom: 95px;
		.pic {
			border: 1px solid $gray3;
			margin-bottom: 30px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.meta {
			margin-bottom: 14px;
		}
		
		.title {
			flex: 1;
			font-weight: 700;
			font-size: 24px;
			line-height: 44px;
		}
		.summary {
			font-size: 14px;
			line-height: 1.8;
		}
		.buttons {
			margin-top: 30px;
			display: flex;
			justify-content: flex-end;
		}
		.mod-basic-btn {
			margin-left: 24px;
		}
	}
 }