.js-property-list__more-list {
	display: none;
}
// ビジネス
@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
