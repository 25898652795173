// 共通 採用情報 PC
.footer-recruit {
	position: relative;
	z-index: $zIndex1;
	min-height: 600px;
	padding: 164px 0 20px;

	&__contents {
		position: relative;
		z-index: 2;
		@include setPcContents();
	}

	.mod-title__en  {
		margin-bottom: 75px;
	}

	.mod-title__ja  {
		margin-bottom: 38px;
	}

	&__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__description {
		color: $white;
		font-size: 15px;
		line-height: 30px;
		max-width: 530px;
	}

	.mod-basic-btn {
		padding-top: 1px;
		margin-left: 30px;
	}
}