// 企業情報ページ SP
.company-page {
	overflow: hidden;
	margin-bottom: -1px;
}


// 
// コンテンツ
// 
.company__basic-contents {
	margin-top: 40px;
	margin-bottom: 0px;
	.picture {
		display: block;
		width: 100%;
		margin-bottom: 36px;
		img {
			width: 100%;
			height: auto;
		}
	}
	.contents {
		margin: 0 35px;
	}
	.title {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.9;
		margin-bottom: 30px;
	}

	.description {
		font-size: 13px;
		line-height: 2;
	}
	
	.message__name {
		margin-top: 20px;
		text-align: right;
		.position {
			display: block;
			font-size: 13px;
			line-height: 2;
			padding-bottom: 1px;
			padding-bottom: 3px;
		}
		.name {
			display: block;
			img{
				width: 130px;
			}
		}
	}
}

/**
 * 会社概要
 **/

.company-page {
	padding-bottom: 1px;
	margin-bottom: -1px;
}

/**
 * アクセス
 **/
.company__access {
	position: relative;
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 140px;
	.map {
		position: relative;
		margin-left: -35px;
		margin-right: -35px;
		margin-bottom: 40px;
		&__frame {
			position: relative;
			width: 100%;
			height: 0;
			padding-top: 165.25%;
		}
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}

	.title {
		font-size: 13px;
		line-height: 24px;
		font-weight: 700;
	}
	.address {
		font-size: 13px;
		line-height: 26px;
		margin-bottom: 5px;
	}
	.info {
		color: $gray1;
		font-size: 13px;
		line-height: 26px;
	}
}

/**
 * CSR
 */
.company-page {
	.lead-text {
		font-size: 13px;
		line-height: 26px;
		margin-left: 35px;
		margin-right: 35px;
		margin-bottom: 75px;
	}
 }

.company__csr {
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 150px;
	.csr-title {
		font-weight: 700;
		font-size: 24px;
		line-height: 44px;
		padding-bottom: 16;
		border-bottom: 1px solid $gray4;
		margin-bottom: 48px;
	}

	.csr-article {
		margin-bottom: 80px;
		.pic {
			border: 1px solid $gray3;
			margin-bottom: 25px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.meta {
			margin-bottom: 20px;
		}
		.date {
			font-weight: 700;
			font-size: 11px;
			line-height: 22px;
			margin-right: 25px;
			padding-top: 5px;
		}
		.title {
			flex: 1;
			font-weight: 700;
			font-size: 20px;
			line-height: 36px;
		}
		.summary {
			font-size: 13px;
			line-height: 26px;
		}
		.button {
			margin-top: 30px;
			a {
				max-width: 300px;
				width: 100%;
				margin: auto;
			}
		}
	}
 }

 
/**
 * 強みバナー
 **/

.business-banner{
	width: 100%;
	padding-top: 70px;
	img{
		width: 100%;
	}
}



.company__mvv-bg{
	//background-image: url(/assets/img/company/mvv-bg-sp.png);
	//background-size: 100% auto;
	//background-repeat: no-repeat;
	padding-top: 110px;
}


body.mvv-bg,
body.mvv-bg:after{
	background-image: url(/assets/img/company/mvv-bg-sp.png);
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 0 310vw;
}
body.mvv-bg:after{
	background: none;
}
.company__mvv-contents{
	//margin-left: 35px;
	//margin-right: 35px;
	margin-bottom: 90px;
	
	.pcOnly{
		display: none;
	}
	
	
	.mvv-img{
		margin-bottom: 60px;
		img{
			width: 100%;
		}
	}
	&__mission{
		margin-left: 35px;
		margin-right: 35px;
		margin-bottom: 64px;
		.mvv-title{
			margin-bottom: 32px;
			img{
				width: auto;
				height: 125px;
			}
		}
		.mvv-subtitle{
			margin-bottom: 24px;
			font-size: 28px;
			font-weight: bold;
			letter-spacing: 0.5px;
			span{
				font-size: 36px;
				letter-spacing: 0.8px;
			}	
		}
		.mvv-lead{
			font-size: 13px;
			line-height: 1.9;		
		}
	}
	&__vision{
		margin-left: 35px;
		margin-right: 35px;
		margin-bottom: 64px;
		.mvv-title{
			margin-bottom: 32px;
			img{
				width: auto;
				height: 125px;
			}
		}
		.mvv-subtitle{
			margin-bottom: 24px;
			font-size: 28px;
			font-weight: bold;
			line-height: 1.3;
			text-indent: -0.75em;
			span{
				font-size: 36px;
			}	
		}
		.mvv-lead{
			font-size: 13px;
			line-height: 1.9;		
		}
	}
	&__value{
		margin-left: 35px;
		margin-right: 35px;
		
		.mvv-title{
			margin-bottom: 32px;
			img{
				width: auto;
				height: 125px;
			}
		}
		.mvv-value{
			li{
				display: flex;
				gap: 12px;
				margin-bottom: 32px;
				
				span.num{
					width: 10px;
					font-size: 11px;
					color: $ml_sky;
					font-weight: bold;
				}	
				span.text{
					font-size: 19px;
					font-weight: bold;	
					line-height: 1.4;
				}
			}
			
		}
	}
}


