// トップページ
.top-mv {
	position: relative;
	z-index: $zIndex1;
	width: 100%;
	height: 100vh;

	.top-mv-canvas {
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}

.top-about{
	background: rgba(#000, 0.7);
	&__contents {
		position: relative;
		z-index: 2;
	}
	.movie__contents {
		position: relative;
		z-index: 2;
	}
	.top-about-canvas {
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}

@include pc {
	.top-mv {
		min-height: 400px
	}
	@import "./pc";
}

@include sp {
	@import "./sp";
}


@import "./animate";