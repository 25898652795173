
.js-swipe,
.js-swipe-loop {
	.js-prev a,
	.js-next a{
		cursor: default;
	}

	// 次へボタンの有効化
	&.js-enabled-next {
		.js-next a {
			cursor: pointer;
		}
	}
	// 戻るボタンの有効化
	&.js-enabled-prev {
		.js-prev a {
			cursor: pointer;
		}
	}
	// ドラッグ時のコンテンツ無効化
	&.js-drag {
		.js-slide-item,
		.js-slide-item a,
		a.plan-item {
			pointer-events: none;
		}
	}
}