// 共通ボタン PC

// 共通ボタン

.mod-basic-btn {
	a,
	button {
		transition: color 0.3s $easeOutExpo,
									background 0.3s $easeOutExpo;
		&::before,
		&::after {
			transition: transform 0.6s $easeOutExpo 0.15s;
		}
		&:hover {
			color: $white;
			background: $ml_blue;
			transition: color 0.6s $easeOutExpo 0.15s,
									background 0.6s $easeOutExpo 0.15s;
			&::before {
				transform: scaleX(1);
			}
			&::after {
				transform: scaleY(1);
			}
			&::before,
			&::after {
				transition: transform 0.4s $easeOutExpo;
			}
			span {
				&::after {
					border-left-color: $white;
				}
			}
		}
	}
	&.white {
		a,
		button {
			&:hover {
				color: $black;
				background: $white;
				span {
					&::after {
						border-left-color: $black;
					}
				}
			}
		}
	}
}
