.page-top-scroll {
	.border {
		transform-origin: right top;
		transform: scaleX(1);
		animation: page_top_border_animate 2.0s $easeOutExpo 0.8s infinite;
	}
}

@keyframes page_top_border_animate {
	0% {
		transform-origin: left top;
		transform: scaleX(1);
	}
	49% {
		transform-origin: left top;
		transform: scaleX(0);
	}
	50% {
		transform-origin: right top;
		transform: scaleX(0);
	}
	99% {
		transform-origin: right top;
		transform: scaleX(1);
	}
	100% {
		transform-origin: left top;
		transform: scaleX(1);
	}
}
