// 共通 企業情報 PC

.footer-company {
	.top-page & {
		margin-bottom: -50px;
	}
	&__contents {
		@include setPcContents();
		margin-bottom: 60px;
	}
	.mod-title__en  {
		margin-bottom: 75px;
	}

	.mod-title__ja {
		margin-bottom: 24px;
	}

	&__description {
		font-size: 15px;
		line-height: 30px;
	}
	&__links {
		padding-left: pcw(216);

		@include pcSmall {
			padding-left: pcw(100);
		}
		
		.nav {
			margin-left: -5px;
		}
		.menu {
			display: flex;
			margin-left: auto;
		}
		.item {
			margin-left: 5px;
			width: calc(20% - 5px);
		}
		a {
			position: relative;
			display: block;
			@include imgHover()
		}
		.img {
			position: relative;
			z-index: 1;
			img {
				width: 100%;
				height: auto;
			}
		}
		.info {
			position: absolute;
			z-index: 2;
			left: 16px;
			top: 12px;
		}

		.ja,
		.en {
			display: block;
			color: $white;
			letter-spacing: 0.02em;
			font-weight: 700;
		}

		.ja {
			font-size: pcw(18);
			line-height: pcw(36);
			@include setPcLargeFont(18, 36);
			margin-bottom: 5px;
		}

		.en {
			font-size: pcw(10);
			line-height: pcw(16);
			@include setPcLargeFont(10, 16);
		}
	}
}