// 共通 お問い合わせ SP

.footer-contact {
	position: relative;
	z-index: ($zIndex1 + 1);
	padding-top: 97px;
	margin-bottom: -76px;

	&__contents {
		@include setSpContents();
	}

	.mod-title__en {
		margin-bottom: 70px;
	}

	.mod-title__ja {
		margin-bottom: 30px;
	}

	&__description {
		color: $gray1;
		font-size: 15px;
		line-height: 28px;
		margin-bottom: 73px;
	}

	&__list {
		@media screen and (min-width: 650px) {
			display: flex;
			justify-content: center;
		}
	}

	&__item {
		max-width: 303px;
		margin: 0 auto 5px;
		@media screen and (min-width: 650px) {
			width: calc(33.333% - 6px);
			margin-left: 3px;
			margin-right: 3px;
		}
	}
	&__link,
	&__no-link {
		position: relative;
		display: flex;
		width: 100%;
		height: 160px;
		justify-content: center;
		align-items: center;
		background: $white;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}
	&__link{
		&::after {
			content: "";
			position: absolute;
			right: -2px;
			bottom: -2px;

			border: 6px solid transparent;
			border-left-color: $black;
			transform: rotate(45deg);
		}
	}
	&__link,
	&__no-link {
		.inner {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
		.icon {
			display: block;
			width: 42px;
			height: 42px;
			margin-bottom: 8px;
		}
		.title {
			font-size: 16px;
			line-height: 32px;
			font-weight: 700;
		}

		.customer-service {
			.title {
				font-size: 13px;
				line-height: 26px;
				font-weight: 700;
				margin-bottom: -10px;
			}

			.tel {
				font-size: 28px;
				line-height: 56px;
				margin-bottom: -5px;
			}
			
			.notes {
				text-align: center;
				font-size: 11px;
				line-height: 18px;
				width: 100%;
			}
		}
	}
}