// お問い合わせページ SP

.contact-page {
	padding-top: 64px;
}

.form-contents {
	margin: 0 35px 160px;
	.heading {
		font-weight: bold;
		font-size: 18px;
		line-height: 36px;
		margin-bottom: 25px;
	}
	.summary {
		color: $gray1;
		font-size: 15px;
		line-height: 30px;
		padding-bottom: 48px;
		margin-bottom: 40px;
		border-bottom: 1px solid $gray4;
		a {
			color: $gray1;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.frame-box {
		padding: 20px 20px 15px;
		border: 1px solid $gray4;
		font-size: 14px;
		line-height: 1.8;
		margin-bottom: 40px;
	}
	.noData{
		p{
			font-size: 14px;
			line-height: 1.9;
			text-align: center;
			border-top: 1px solid #CCC;
			padding: 50px 0px 60px;
		}
		div.mod-basic-btn{
			width: 264px;
			margin: 0px auto;
		}
	}

	.required {
		color: $red;
		padding-left: 3px;
	}

	label.radio {
		display: block;
		min-height: 30px;
		position: relative;
		padding-left: 24px;
		//ラジオボタン
		input[type="radio"] {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
		}
		input[type="radio"] + span::before {
			position: absolute;
			display: inline-block;
			content: "";
			box-sizing: border-box;
			border-radius: 7px;
			z-index: 0;
			top: 7px;
			left: 0;
			background-color: transparent;
			width: 14px;
			height: 14px;
			border: 1px solid $black;
		}
		input[type="radio"]:checked + span::after {
			content: "";
			display: block;
			position: absolute;
			top: 10px;
			left: 3px;
			width: 8px;
			height: 8px;
			background: $black;
			border-radius: 50%;
		}
	}
	label.check {
		display: block;
		min-height: 30px;
		position: relative;
		padding-left: 24px;
		input[type="checkbox"] + span::before {
			position: absolute;
			display: inline-block;
			content: "";
			box-sizing: border-box;
			z-index: 0;
			top: 8px;
			left: 0;
			background-color: transparent;
			width: 14px;
			height: 14px;
			border: 1px solid $black;
		}
		input[type="checkbox"]:checked + span::after {
			content: "";
			display: block;
			position: absolute;
			top: 10px;
			left: 5px;
			width: 3px;
			height: 7px;
			transform: rotate(45deg);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
		}
	}
	input[type="text"] {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 30px;
		width: 100%;
		height: 48px;
		padding: 3px  12px;
		background: $gray5;
		border: 1px solid $gray4;
	}

	.input-section {
		.label {
			font-weight: bold;
			font-size: 15px;
			line-height: 30px;
			.notes {
				font-weight: normal;
				color: $gray2;
				padding-left: 5px;
			}
		}
		&.type-radio,
		&.type-input {
			.label {
				display: block;
				margin-bottom: 10px;
			}
		}
		&.type-radio {
			margin-bottom: 24px;
			&.contact {
				margin-bottom: 36px;
			}

			li {
				margin-bottom: 6px;
			}
			.text {
				font-size: 15px;
				line-height: 1.6;
			}
		}
		&.type-input {
			margin-bottom: 24px;
			textarea {
				font-size: 16px;
				line-height: 30px;
				width: 100%;
				height: 210px;
				background: $gray5;
				padding: 6px 12px;
				background: $gray5;
				border: 1px solid $gray4;
				resize: none;
			}
		}
		&.type-select {
			margin-bottom: 30px;
			.input-box {
				position: relative;
				margin-top: 18px;
			}
			select {
				font-size: 16px;
				line-height: 30px;
				border: 1px solid $gray4;
				width: 100%;
				height: 42px;
				padding: 3px 12px;
				padding-right: 35px;
			}
			.arrow {
				position: absolute;
				right: 16px;
				top: 12px;
				//transform: translateY(-50%);
				pointer-events: none;
			}
		}
	}
	.send-data {
		padding: 35px 16px 35px;
		border: 1px solid $gray4;
		text-align: left;
		margin-top: 45px;
		margin-bottom: 25px;
		.check {
			display: inline-block;
			.text {
				font-weight: bold;
				font-size: 18px;
				line-height: 24px;
			}
		}
		.notes {
			display: block;
			text-align: center;
			color: $gray2;
			font-size: 10px;
			line-height: 18px;
			margin-top: 7px;
			margin-bottom: 36px;
		}
		.contents {
			text-align: left;
		}
		.zip-box,
		.addr-box {
			label {
				display: block;
				font-weight: bold;
				font-size: 15px;
				line-height: 30px;
				margin-bottom: 10px;
			}
		}
		.zip-box  {
			margin-bottom: 24px;
		}
		.zip-box .input-box {
			position: relative;
			display: flex;
			&:after {
				content: "";
				position: absolute;
				left: 82px;
				top: 21px;
				width: 10px;
				height: 1px;
				background: $black;
			}
		}
		.zip1 {
			width: 72px;
			padding: 3px 18px;
		}
		.zip2 {
			margin-left: 30px;
			width: 96px;
			padding: 3px 18px;
		}

	}
	.agree {
		margin-top: 40px;
		margin-bottom: 40px;
		label {
			font-size: 15px;
			line-height: 30px;
			width: 250px;
			margin: auto;
		}
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	.mod-basic-btn {
		button {
			margin: auto ;
		}
	}
	.comfirmBtn{
		padding-top: 20px;
		.comfirm{
			margin-bottom: 20px;
			a,
			button{
				color: $gray2;
				border-color: $gray2;
				
				&:hover {
					color: $white;
					background: $gray2;
					border-color: $gray2;
					span {
						&::after {
							border-left-color: $white;
						}
					}
				}
				&::before {
					background: $gray2;
				}
				&::after {
					background: $gray2;
				}
				span {
					font-size: 13px;
					span:before{
						border-left-color: $gray2;
					}
					&::after {
						border-left-color: $gray2;
					}
				}
			}
			span {
				&::before {
					border-left: 1px solid $gray2;
					border-top: 1px solid $gray2;
				}
				&::after {
					border-left-color: $gray2;
				}
			}
			
		}
	}
	
	//エラー
	span.err{
		display: block;
		color: #FF0000;
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 12px;
	}
	#agree_error.err{
		text-align: center;
	}
	input.err,
	textbox.err,
	select.err{
		border: red 1px solid !important;
	}
	
	//確認画面
	p.comfirm{
		font-size: 13px;
		line-height: 1.7;
	}
	p.comfirm_yuso{
		text-align: center;
		margin-bottom: 25px;
		font-weight: bold;
		font-size: 13px;
	}
	
	//完了メール
	p.thnks{
		padding: 35px 16px 35px;
		border: 1px solid $gray4;
		font-size: 13px;
		line-height: 1.8;
		margin-bottom: 40px;
	}
	
	
}
