// ご契約中のオーナー様 SP
.for-owner-page {
	position: relative;
	z-index: $zIndex2;
	padding-top: 64px;
	overflow: hidden;
	margin-bottom: -1px;
}




.for-owner__contents {
	margin-left: 35px;
	margin-right: 35px;
	margin-top: 59px;
	margin-bottom: 150px;

	
	.block {
		margin-bottom: 80px;
		.pic {
			border: 1px solid $gray3;
			margin-bottom: 25px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.meta {
			margin-bottom: 20px;
		}
		.title {
			flex: 1;
			font-weight: 700;
			font-size: 20px;
			line-height: 36px;
		}
		.summary {
			font-size: 13px;
			line-height: 26px;
		}
		.buttons {
			margin-top: 30px;
			a {
				max-width: 300px;
				width: 100%;
				margin: auto;
			}
			.mod-basic-btn {
				margin-top: 24px;
			}
		}
	}
 }
