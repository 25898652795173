.top-page {
	.hamburger {
		transform: translateY(-120%);
	}
	.site-header {
		transform: translateY(-100%);

		@media screen and (max-width: $sp) {
			.client-menu .mod-basic-btn {
				transform: translateX(-35px);
				opacity: 0;
				@include pc {
					filter: blur(10px);
				}
			}
		}
	}
	
	.top-mv {
		.msg1,
		.msg2,
		.msg3,
		.msg4 {
			transform: translateX(-35px);
			opacity: 0;
			@include pc {
				filter: blur(10px);
			}
		}
		.news-item {
			transform: translateX(-20px);
			opacity: 0;
			@include pc {
				filter: blur(10px);
			}
		}
		.scroll {
			transform: translateY(-20px);
			opacity: 0;
			@include pc {
				filter: blur(10px);
			}
		}
	}
	&.js-animated.js-animated-completed {
		.site-header {
			transition: transform 0.8s $easeOutExpo,
										height 0.4s $easeOutExpo;
			transition-delay: 0;
		}
	}
	&.js-animated {
		//ヘッダー部分
		.hamburger,
		.site-header {
			transform: translateY(0);
			transition: transform 1.6s $easeOutExpo;
			transition-delay: 1.4s;
			@media screen and (max-width: $sp) {
				.client-menu .mod-basic-btn {
					transform: translate(0, 0);
					opacity: 1;
					transition: opacity 1s $easeOutExpo,
												transform 1.2s $easeOutExpo;
					@include pc {
						filter: blur(0);
						transition: opacity 0.3s $easeOutExpo,
													filter 1.2s $easeOutExpo,
													transform 1.2s $easeOutExpo;
					}
					transition-delay: 1.8s;
					&:nth-child(2) {
						transition-delay: 1.9s;
					}
				}
			}
		}
		// メインビジュアル部分
		.top-mv {
			.msg1,
			.msg2,
			.msg3,
			.msg4,
			.news-item,
			.scroll {
				transform: translate(0, 0);
				opacity: 1;
				transition: opacity 1s $easeOutExpo,
											transform 1.2s $easeOutExpo;
				@include pc {
					filter: blur(0);
					transition: opacity 0.3s $easeOutExpo,
												filter 1.2s $easeOutExpo,
												transform 1.2s $easeOutExpo;
				}
			}
			.msg2 {
				transition-delay: 0.1s;
			}
			.msg3 {
				transition-delay: 0.15s;
			}
			.msg4 {
				transition-delay: 0.25s;
			}

			.news-item {
				transition-delay: 0.45s;
			}
			.scroll {
				transition-delay: 0.60s;
			}
		}
	}
}
/**
 * スクロールアテンションアニメーション
 */ 
.top-mv {
	&.js-animated {
		.scroll {
			.border {
				transform-origin: left top;
				animation: scroll_border_animate 2.0s $easeOutExpo 0.8s infinite;
			}
		}
	}
}

@keyframes scroll_border_animate {
	0% {
		transform-origin: left top;
		transform: scaleX(0);
	}
	49% {
		transform-origin: left top;
		transform: scaleX(1);
	}
	50% {
		transform-origin: right top;
		transform: scaleX(1);
	}
	99% {
		transform-origin: right top;
		transform: scaleX(0);
	}
	100% {
		transform-origin: left top;
		transform: scaleX(0);
	}
}


