// ビジネス PC
.business-page {
	overflow: hidden;
	margin-bottom: -1px;
	
	.section-title {
		font-weight: bold;
		font-size: 32px;
		line-height: 58px;
		border-bottom: 1px solid $gray4;
		padding-bottom: 30px;
	}
}
.contact-link {
	margin-top: 96px;
	a {
		display: block;
		background: #000;
		&:hover {
			img {
				opacity: 0.7;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
		transition: opacity 0.6s $easeOutExpo;
	}
}

/**
 * マンション経営をお考えの方へ
 */
.mm-sec-contents {
	@include setPcContents();
	margin-top: 75px;
	margin-bottom: 142px;
	.contents {
		padding-left: pcw(200);
	}
	.section-title {
		margin-bottom: 52px;
	}
	.heading {
		color: $ml_blue;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.9;
		text-align: center;
		margin-bottom: 60px;
	}
	.subhead {
		font-weight: bold;
		font-size: 19px;
		line-height: 1.8;
		margin-bottom: 34px;
	}
	.pic {
		img {
			width: 100%;
			height: auto;
		}
	}
	.pic1 {
		margin-bottom: 96px;
	}
	.pic2 {
		margin-bottom: 76px;
	}
	.note {
		font-size: 14px;
		line-height: 26px;
		margin-bottom: 42px;
	}
	.pic4 {
		margin-bottom: 74px;
	}

	.merit {
		padding-top: 54px;
		background: url(/assets/img/business/img_mm5.png) no-repeat 0 0;
		background-size: pcw(236) auto;
		text-align: center;
		&-title {
			text-align: center;
			display: inline-block;
			color: $ml_sky;
			font-weight: 700;
			line-height: 80px;
			margin-bottom: 150px;
			border-bottom: 1px solid $gray4;
			.num {
				font-size: 52px;
			}
			.text {
				font-size: 24px;
			}
		}
		&-contents {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 -12px;
		}
		&-block {
			position: relative;
			width: calc(50% - 24px);
			margin: 0 12px 80px;
			padding: 45px 32px 35px;
			background: $gray5;
			border: 1px solid $gray4;
			
			.merit-num {
				color: $ml_sky;
				position: absolute;
				top: -42px;
				left: 50%;
				transform: translateX(-50%);
				span {
					display: block;
				}
				.text {
					font-weight: bold;
					font-size: 13px;
					line-height: 28px;
				}
				.num {
					font-weight: bold;
					font-size: 40px;
					line-height: 42px;
				}
			}
			.title{
				text-align: center;
				font-weight: bold;
				font-size: 24px;
				line-height: 44px;
				margin-bottom: 15px;
			}

			.summary {
				text-align: left;
				font-size: 14px;
				line-height: 26px;
			}
		}
	}
	
	.risk-block {
		border-top: 1px solid $gray4;
		border-bottom: 1px solid $gray4;
		margin-bottom: -1px;
		&.open {
			.risk__q {
				&::after {
					transform: scaleY(0);
				}
			}
			// .risk__a {
			// 	height: auto;
			// 	opacity: 1;
			// }
		}
	}
	
	.risk__q {
		position: relative;
		display: flex;
		align-items: center;
		padding: 16px 0;
		padding-right: 32px;
		&::before,
		&::after {
			content: "";
			position: absolute;
			top: calc(50% - 8px);
			right: 8px;
			width: 1px;
			height: 16px;
			background: $black;
		}
		&::before {
			transform: rotate(90deg);
		}
		&::after {
			transition: transform 0.6s $easeOutExpo;
		}
		.num-box {
			color: $white;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 56px;
			height: 56px;
			background: $ml_sky;
			.num,
			.next {
				font-weight: bold;
				line-height: 26px;
			}
			.num{
				font-size: 24px;
			}
			.next {
				font-size: 12px;
			}
		}
		.summary {
			flex: 1;
			font-weight: bold;
			font-size: 24px;
			line-height: 44px;
			margin-left: 32px;
		}
	}
	.risk__a {
		height: 0;
		opacity: 0;
		overflow: hidden;
		
		.inner {
			font-size: 14px;
			line-height: 26px;
			padding-left: 92px;
			padding-bottom: 30px;
		}
	}

	.faq-block {
		&__contents {
			display: flex;
		}
		&__data {
			flex: 1;
		}
		.faq-q {
			color: $ml_blue;
			font-weight: bold;
			font-size: 24px;
			line-height: 1.7;
			margin-bottom: 11px;
			text-indent: -32px;
			padding-left: 32px;
		}
		.faq-a {
			.title {
				font-weight: bold;
				font-size: 16px;
				line-height: 30px;
				margin-bottom: 6px;
				text-indent: -20px;
				padding-left: 20px;
				span {
					font-size: 24px;
				}
			}
			p {
				font-size: 14px;
				line-height: 24px;
				padding-left: 30px;
			}
		}
		.mod-basic-btn {
			display: flex;
			justify-content: flex-end;
			margin-top: 22px;
			a {
				width: 320px;
			}
		}
		&.q1{
			margin-bottom: 85px;
			.faq-block__data {
				padding-top: 15px;
			}
			.faq-block__img {
				width: 232px;
				margin-left: 55px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q2 {
			margin-bottom: 60px;
			.faq-block__contents {
				flex-direction: row-reverse;
			}
			.faq-block__img {
				width: 240px;
				margin-right: 55px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q3 {
			.faq-block__data {
				padding-top: 25px;
			}
			
			.faq-block__img {
				width: 232px;
				margin-left: 55px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q4 {
			margin-bottom: 64px;
			.faq-block__contents {
				flex-direction: row-reverse;
			}
			
			.faq-block__data {
				padding-top: 41px;
			}
			
			.faq-block__img {
				width: 272px;
				margin-right: 16px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q5{
			margin-bottom: 82px;
			.faq-block__data {
				padding-top: 18px;
			}
			.faq-block__img {
				width: 224px;
				margin-left: 64px;
				img {
					width: 100%;
					height: auto;
				}
			}
			
		}
		&.q6 {
			margin-bottom: 64px;
			.faq-block__contents {
				flex-direction: row-reverse;
			}
			
			.faq-block__data {
				padding-top: 41px;
			}
			
			.faq-block__img {
				width: 225px;
				margin-right: 63px;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	.faq-table-data {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 36px;
		margin-bottom: 95px;
		.data {
			width: 62%;
			.meta {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;
			}
			.title {
				font-weight: bold;
				font-size: 14px;
				line-height: 26px;
			}
			.unit {
				color: $gray1;
				font-size: 10px;
				line-height: 20px;
			}
			img {
				width: 100%;
				height: auto;
			}
			.notes {
				color: $gray1;
				font-size: 12px;
				line-height: 26px;
				margin-top: 16px;
			}
		}
		.image {
			width: 32%;
			img {
				width: 100%;
				height: auto;
			}
			
		}
	}
}


/**
 * メインランドグループの強み
 */
.adv-sec-contents {
	@include setPcContents();
	margin-bottom: 140px;

	&.data-section {
		margin-top: 75px;
	}

	.contents {
		padding-left: pcw(200);
	}

	.section-title {
		margin-bottom: 56px;
		.title-notes {
			font-weight: normal;
			color: $gray1;
			font-size: 15px;
			padding-left: 60px;
		}
	}
	.heading {
		color: $ml_blue;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.9;
		margin-bottom: 15px;
	}
	.summary {
		font-size: 14px;
		line-height: 1.8;
		margin-bottom: 45px;
	}
	.data-box {
		margin-bottom: 30px;
		display: flex;
		justify-content: space-between;
		.label {
			font-size: 13px;
			line-height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 32px;
			min-width: 150px;
			padding: 5px 10px;
			border: 1px solid $gray4;
			margin-bottom: 4px;
			span {
				font-size: 10px;
				line-height: 18px;
				padding-bottom: 6px;
				margin-left: 5px;
			}
		}
		.data {
			color: $ml_blue;
			font-weight: bold;
			text-align: center;
			font-size: 22px;
			line-height: 64px;
			.num {
				font-size: 38px;
			}
		}
	}

	.data-notes {
		text-align: right;
		color: $gray1;
		font-size: 12px;
		line-height: 1.8;
	}
	.data-image {
		margin-top: 60px;
		img {
			width: 100%;
			height: auto;
		}
	}

	.message {
		font-size: 15px;
		line-height: 30px;
		text-align: center;
		padding: 30px 20px;
		background: $gray5;
		border: 1px solid $gray4;
	}
	&.about-section {
		.table {
			margin-top: 60px;
			margin-bottom: 42px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.notes {
			color: $gray1;
			font-size: 12px;
			line-height: 1.8;
			margin-bottom: 82px;
		}
		.about-box {
			border-bottom: 1px solid $gray4;
			margin-bottom: 30px;
			.subhead {
				font-weight: bold;
				font-size: 17px;
				line-height: 1.8;
				margin-bottom: 10px;
			}
			.summary {
				margin-bottom: 20px;
			}
		}
	}
	&.contract-section {
		.image {
			margin: 64px 0;
			img {
				width: 100%;
				height: auto;
			}
		}
		.info {
			text-align: center;
			line-height: 80px;
			font-size: 24px;
			.blue {
				color: $ml_blue;
			}
			.num {
				font-weight: bold;
				font-size: 52px;
			}
			.unit {
				font-weight: bold;
				font-size: 44px;
			}
		}
		.notes {
			width: 590px;
			margin: auto;
			text-align: left;
			color: $gray1;
			font-size: 14px;
			line-height: 1.8;
			padding-top: 4px;
			margin-bottom: 58px;
		}
	}
	&.follow-section {
		margin-bottom: 120px;
		overflow: hidden;
		.image {
			margin: 64px 0 30px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.lead-title {
			font-weight: bold;
			font-size: 24px;
			line-height: 1.5;
			margin-bottom: 15px;
		}
		.summary {
			margin-bottom: 60px;
		}
		.articles {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -20px;
			&-item {
				width: calc(50% - 40px);
				margin: 0 20px 50px;
				img {
					width: 100%;
					height: auto;
				}
			}
			a {
				display: block;
			}
			.thumb {
				margin-bottom: 15px;
			}
			.title {
				font-weight: bold;
				font-size: 16px;
				line-height: 1.7;
				padding-top: 5px;
				margin-bottom: 10px;
			}
			.lead {
				font-size: 13px;
				line-height: 1.8;
				span{
					color: #666;
					font-size: 11px;
				}
			}
		}
	}
}


/**
 * オーナデータ
 */
.owner-data__graph {
	padding-left: pcw(200);
	@include setPcContents();
	margin-bottom: 145px;
	.title {
		color: $ml_blue;
		font-weight: bold;
		font-size: 24px;
		line-height: 44px;
		margin-bottom: 30px;
	}

	.graphs {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.item {
			width: 50%;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

.owner-data__voice {
	@include setPcContents();
	margin-bottom: 50px;
	.section-title {
		margin-bottom: 85px;
	}
	.contents {
		padding-left: pcw(200);

		&-inner {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -24px;
		}
	}

	.block {
		width: calc(50% - 48px);
		margin: 0 24px 90px;
	}
	.title {
		color: $ml_blue;
		font-weight: bold;
		font-size: 24px;
		line-height: 44px;
		text-align: center;
		margin-bottom: 23px;
	}

	.data {
		font-size: 13px;
		line-height: 26px;
		text-align: center;
		background: $gray5;
		border: 1px solid $gray4;
		padding: 15px 5px 17px;
		margin-bottom: 23px;
	}
	.name {
		font-weight: bold;
		font-size: 16px;
		line-height: 1.8;
		margin-bottom: 6px;
	}
	.summary {
		font-size: 13px;
		line-height: 1.8;
	}
}

.owner-data__interview {
	@include setPcContents();
	margin-bottom: 130px;

	.section-title {
		margin-bottom: 65px;
	}

	.contents {
		padding-left: pcw(200);
		margin-bottom: 160px;

		&-inner {
			margin-bottom: 96px;
			position: relative;
		}
		.thumb {
			width: 100%;
			height: auto;
		}

		@include moviePlay(1, true);

		.youtube {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			z-index: 1;
			overflow: hidden;
			&-contents {
				// position: absolute;
				// left: 0;
				// top: 0;
				width: 100%;
				height: 0;
				padding-bottom: 56.25%;
			}
			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

/**
 * メインステージ > コンセプト
 **/
.main-stage-concept {
	@include setPcContents();
	margin-bottom: 136px;

	.section-title {
		margin-bottom: 56px;
	}

	.contents {
		padding-left: calc(20% - 40px);
	}
	
	.main-message-block {
		margin-bottom: 56px;
		display: flex;
		.image {
			flex-shrink: 0;
			width: 62%;
			margin-right: 40px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.meta {
			width: 100%;
		}
		.summary {
			font-weight: 400;
			font-size: 13px;
			line-height: 190%;
		}
	}
	.massages {
		display: flex;
		margin-left: -24px;
	}
	.massages-block {
		width: calc(33.333% - 24px);
		margin-left: 24px;
		.image {
			margin-bottom: 16px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.title {
			font-weight: 700;
			font-size: 18px;
			line-height: 170%;
			margin-bottom: 12px;
		}
		.summary {
			font-weight: 400;
			font-size: 13px;
			line-height: 175%;
		}
		.notes {
			margin-top: 4px;
			font-weight: 400;
			font-size: 13px;
			line-height: 170%;
			color: $gray1;
			text-indent: -1.2rem;
			padding-left: 1.3rem;
			&::before {
				content: "※"
			}
		}
	}
}

/**
 * 物件一覧リスト
 **/
.property-list-page {
	&__contents {
		@include setPcContents();
		padding-left: calc(20% - 40px);
		margin-bottom: 176px;
	}
	.mod-property-item {
		width: calc(50% - 40px);
		margin-left: 40px;
		.link {
			padding-bottom: 20px;
		}
		.name {
			font-weight: 500;
			font-size: 22px;
			margin-bottom: 12px;
		}
	}

	&__contents {
		.more {
			margin-top: 32px;
			.link {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 40px;
				height: 64px;
				background: $gray4;
				transition: 0.8s $easeOutExpo background;
				&::before,
				&::after {
					position: absolute;
					content: "";
					width: 16px;
					height: 1px;
					top: 50%;
					right: 24px;
					background: $ml_blue;
					transition: 0.8s $easeOutExpo background;
				}
				&::after {
					transform: rotate(90deg);
				}
				&:hover {
					background: $ml_blue;
					&::before,
					&::after {
						background: $white;
					}
					.text {
						color: $white;
					}
				}
			}
			.text {
				font-weight: 700;
				font-size: 15px;
				line-height: 30px;
				letter-spacing: 0.02em;
				color: $ml_blue;
				transition: 0.8s $easeOutExpo color;
			}
		}
	}
}

/**
 * 物件概要
 */
.pp-page-description {
	font-weight: 400;
	font-size: 15px;
	line-height: 195%;
	@include setPcContents();
	margin-bottom: 80px;
}
.pp-main-property {
	position: relative;
	@include setPcContents();
	margin-bottom: 136px;
	.contents {
		display: flex;
	}
	.images {
		flex-shrink: 0;
		width: 50%;
		max-width: 1100px;
		margin-right: pcw(60);
	}
	.main-images {
		position: relative;
		margin-bottom: 12px;
		.image {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			z-index: 1;
			width: 100%;
			aspect-ratio: 2045 / 1414;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.current {
			position: relative;
			z-index: 2;
			opacity: 1;
		}
	}
	.thumb-images {
		display: flex;
		flex-wrap: wrap;
		margin-left: -12px;
		.image {
			margin-left: 12px;
			margin-top: 12px;
			width: calc(16.66% - 12px);
			a {
				position: relative;
				display: block;
				width: 100%;
				aspect-ratio: 2045 / 1414;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					box-sizing: border-box;
					border: 1px solid rgba($white, 0);
					transition: 0.6s border $easeOutExpo;
				}
				&:hover {
					&::after {
						border: 1px solid rgba($ml_sky, 0.7);
					}
				}
				&.current {
					&::after {
						border: 1px solid $ml_sky;
					}
				}
			}
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
	.overview {
		width: 100%;
		.title {
			font-weight: 700;
			font-size: 32px;
			line-height: 180%;
			padding-bottom: 24;
			border-bottom: 1px solid $gray4;
			margin-bottom: 40px;
		}
		.spec-list {
			margin-bottom: 64px;
		}
		.spec {
			display: flex;
			font-weight: 400;
			font-size: 13px;
			line-height: 175%;
			margin-bottom: 10px;
			.label {
				color: $ml_blue;
				flex-shrink: 0;
				width: 80px
			}
		}
		.mod-basic-btn {
			margin-top: 16px;
			a {
				max-width: 350px;
				width: 100%;
			}
		}
	}
}

/**
 * 物件詳細ページ共通
 */
.pp-detail-section {
	@include setPcContents();
	margin-bottom: 136px;

	.section-title {
		margin-bottom: 56px;
	}
	.contents {
		margin-left: 20%;
	}
}

/**
 * 物件詳細ページ > 間取り
 */
.pp-floor-plan {
	.plan-list {
		position: relative;
		overflow: hidden;
	}
	.slide-area-wrapper {
		padding-right: 20px;
	}
	.slide-area {
		display: flex;
	}
	.plan-item {
		margin-left: 20px;
		width: 210px;
		flex-shrink: 0;
		.image {
			margin-bottom: 16px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.name {
			font-weight: 700;
			font-size: 15px;
			line-height: 175%;
			margin-bottom: 8px;
		}
		.spec {
			margin-bottom: 2px;
			font-weight: 400;
			font-size: 13px;
			line-height: 175%;
			display: flex;
			dt {
				flex-shrink: 0;
				width: 100px;
				color: $ml_blue;
			}
		}
	}
	.js-prev,
	.js-next {
		position: absolute;
		z-index: 2;
		top: 45%;
		width: 32px;
		height: 32px;
		transform: translateY(-50%);
		a {
			position: relative;
			display: block;
			width: 0;
			height: 0;
			border: 16px solid transparent;
			transition: border 0.6s $easeOutExpo
		}
	}
	.js-prev {
		left: 0;
		a {
			border-right-color: $gray4;
		}
	}
	.js-next {
		right: 0;
		a {
			border-left-color: $gray4;
		}
	}
	.js-enabled-next {
		.js-next {
			a {
				border-left-color: $black;
				&:hover {
					border-left-color: $ml_sky;
				}
			}
		}
	}
	// 戻るボタンの有効化
	.js-enabled-prev {
		.js-prev {
			a {
				border-right-color: $black;
				&:hover {
					border-right-color: $ml_sky;
				}
			}
		}
	}
}
/**
 * 物件詳細ページ > パノラマ
 */
.pp-panorama {
	.panorama-item {
		margin-bottom: 56px;
		.title {
			font-weight: 500;
			font-size: 22px;
			line-height: 44px;
			margin-bottom: 16px;
		}
		.iframe {
			position: relative;
			padding-top: 62%;
			height: 0;
		}
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
}
/**
 * 物件詳細ページ > ロケーション
 */
.pp-location {
	@include pcImageGallery();
}

/**
 * 物件詳細ページ > お問い合わせ
 */
.pp-contact {
	.description {
		font-weight: 400;
		font-size: 13px;
		line-height: 190%;
		margin-bottom: 40px;
	}
	.links {
		display: flex;
		margin-left: -4px;
	}
	.link-item {
		width: calc(50% - 4px);
		margin-left: 4px;
		img {
			width: 100%;
			height: auto;
		}
	}
	.link {
		position: relative;
		display: block;
		@include imgHover()
	}
}