// 共通 採用情報 SP

.footer-recruit {
	position: relative;
	z-index: $zIndex1;
	min-height: 710px;
	background: url(../img/common/footer_recruit_bg.jpg) no-repeat 50% 50%;
	background-size:  cover;
	padding: 173px 0 140px;

	&__contents {
		position: relative;
		z-index: 2;
		@include setSpContents();
	}

	.mod-title__en {
		margin-bottom: 70px;
	}

	.mod-title__ja  {
		margin-bottom: 30px;
	}

	&__description {
		color: $white;
		font-size: 15px;
		line-height: 28px;
		margin-bottom: 73px;
	}

}
