 // 共通ページナビ SP
.mod-page__nav {
	padding-bottom: 13px;
	margin: 20px 35px 45px;
	border-bottom: 1px solid $gray4;

	.label {
		position: relative;
		font-size: 15px;
		line-height: 2;
		font-weight: bold;

		&::after,
		&::before {
			content: "";
			top: 0;
			bottom: 0;
			right: 8px;
			position: absolute;
			width: 1px;
			height: 16px;
			margin: auto;
			background: $black;
		}
		&::after {
			transform: rotate(90deg);
		}
		&::before {
			transition: transform 0.8s $easeOutExpo;
		}
		&.open {
			&::before {
				transform: scaleY(0);
			}
		}
	}
	.js-page-nav-toggle__list {
		height: 0;
		overflow: hidden;
	}
	.list {
		padding-top: 9px;
		display: flex;
		flex-wrap: wrap;
		margin-right: -22px;
	}
	.item {
		margin-right: 22px;
		margin-bottom: 12px;
	}
	.link {
		position: relative;
		color: $gray3;
		font-size: 13px;
		line-height: 1.65;
		display: flex;
		padding-right: 11px;
		&.current {
			color: $ml_blue;
			&::before {
				background: $ml_blue;
				transform: scaleX(1);
			}
			&::after {
				border-left-color: $ml_blue;
			}
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 100%;
			height: 1px;
			background: $gray4;
			transform: scaleX(0);
			transform-origin: right top;
			transition: transform 0.8s $easeOutExpo;
		}

		&::after {
			content: "";
			position: absolute;
			right: -2px;
			bottom: 0;	
			border: 3px solid transparent;
			border-left-color: $gray4;
			transform: rotate(45deg);
		}
	}
}


