// 個人情報保護方針 PC

.policy-page {
	position: relative;
	z-index: $zIndex2;
	padding-top: pcw(102);
	margin-bottom: -1px;
	overflow: hidden;
}

.policy__group {
	margin-bottom: 135px;
}

.policy__contents {
	padding-left: pcw(200);
	@include setPcContents();

	.lead-text {
		font-size: 14px;
		line-height: 30px;
		margin-bottom: 55px;
	}

	.section {
		margin-top: -1px;
		border-top: 1px solid $gray4;
		border-bottom: 1px solid $gray4;
		padding-top: 55px;
		padding-bottom: 20px;
		&.bottom-none {
			border-bottom: none;
		}

		.title {
			font-weight: bold;
			font-size: 16px;
			line-height: 30px;
			margin-bottom: 10px;
		}

		.summary,
		.notes,
		.list {
			font-size: 14px;
			line-height: 1.9;
			margin-top: 6px;
		}

		.summary {
			padding-left: 14px;
		}

		.list {
			margin-top: 6px;
			text-indent: -14px;
			padding-left: 30px;
			margin-bottom: 10px;
			.list {
				padding-left: 14px;	
			}
		}
	}
	.update-date-at {
		text-align: right;
		font-size: 14px;
		line-height: 26px;
	}
	.support {
		margin-top: 64px;
		.info {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $gray4;
		}
	}
}