// 共通 企業情報

.footer-company {
	position: relative;
	z-index: $zIndex2;
}

@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
