// ニュース

.news-list__more {
	display: none;
}

@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
