.js-scroll-img {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	&-scroll {
		position: relative;
		width: 100%;
		height: 100%;
	}
	&-inner {
		position: relative;
		width: 100%;
		height: 100%;
		transform: scale(1.20);
		transform-origin: top center;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}