// 共通ボタン

.mod-basic-btn {
	a,
	button {
		position: relative;
		color: $ml_blue;
		display: flex;
		width: 264px;
		height: 48px;
		// border: 1px solid $ml_blue;
		&.flex {
			max-width: 264px;
			width: 100%;
		}
		&::before,
		&::after {
			content: "";
			position: absolute;
			z-index: 1;
		}
		&::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			right: 1px;
			background: $ml_blue;
			transform: scaleX(0.8);
			transform-origin: left top;
		}
		&::after {
			top: 0;
			right: 0;
			width: 1px;
			height: 100%;
			background: $ml_blue;
			transform: scaleY(0.38);
			transform-origin: left top;
		}
	}
	span {
		z-index: 6;
		font-size: 14px;
		line-height: 28px;
		font-weight: 700;
		letter-spacing: 0.02em;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		&::before,
		&::after {
			content: "";
			z-index: 2;
		}
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			border-left: 1px solid $ml_blue;
			border-top: 1px solid $ml_blue;
		}
		&::after {
			content: "";
			position: absolute;
			z-index: 3;
			right: -4px;
			bottom: -4px;
			border: 6px solid transparent;
			border-left-color: $ml_blue;
			transform: rotate(45deg);
			transition: border-left-color 0.3s 0.1s;
		}
	}
	&.middle {
		a,
		button {
			width: 240px;
		}
		span {
			font-size: 15px;
			line-height: 30px;
		}
	}
	&.small {
		a {
			width: 190px;
			height: 40px;
		}
		span {
			font-size: 10px;
			line-height: 16px;
			font-weight: 400;
		}
	}
	&.white {
		a,
		button {
			color: $white;
			&::before {
				background: $white;
			}
			&::after {
				background: $white;
			}
		}
		span {
			&::before {
				border-left-color:  $white;
				border-top-color:  $white;
			}
			&::after {
				border-left-color: $white;
			}
		}
	}
}

@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
