// トップページ SP

.top-mv {
	.message {
		position: absolute;
		z-index: 2;
		top: 47%;
		left: 10px;
		right: 10px;
		max-width: 350px;
		span {
			display: block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	.news-item,
	.scroll {
		display: none;
	}
}
// 
// MAINLANDについて
// 
.top-about {
	position: relative;
	z-index: $zIndex1;
	padding-top: 1px;
	overflow: hidden;
	&__contents {
		@include setSpContents();
	}

	.message {
		color: $white;
		font-size: 13px;
		line-height: 38px;
		font-weight: 700;
		text-align: center;
		letter-spacing: 0.04em;
		padding-top: 200px;
	}
	.movie {
		position: relative;
		margin-left: -36px;
		margin-right: -36px;
		padding: 50vw 0;
		&__contents {
			position: relative;
			margin: 0 18px;
		}
		.thumb {
			width: 100%;
			height: auto;
		}
		@include moviePlay(0.75, false);
		
		.youtube {
			position: absolute;
			left: 0;
			bottom: 0;
			top: 0;
			right: 0;
			z-index: 1;
			// overflow: hidden;
			&-contents {
				// position: absolute;
				// left: 0;
				// top: 0;
				width: 100%;
				height: 0;
				padding-bottom: 56.25%;
			}
			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}
.top-contents-wrapper {
	position: relative;
	z-index: $zIndex1+1;
	&::after {
		content: "";
		z-index: $zIndex1 - 1;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 35px;
		background: $white;
	}
}
// 
// Property
// 
.top-property {
	position: relative;
	z-index: $zIndex2;
	margin-bottom: 153px;

	&__contents {
		@include setPcContents();
		margin-bottom: 95px;
	}
	.mod-title__en  {
		margin-bottom: 70px;
	}

	.mod-title__ja {
		margin-bottom: 30px;
	}

	&__description {
		font-size: 15px;
		line-height: 30px;
	}
	
	&__item {
		position: relative;
		margin-bottom: 120px;
		&.type1 {
			.thumb {
				padding-right: 36px;

				@include spSmall {
					padding-right: 20px;
				}
			}
			.num {
				right: 10px;
			}
		}
		&.type2 {
			.thumb {
				padding-left: 36px;

				@include spSmall {
					padding-left: 20px;
				}
			}
			.num {
				left: 10px;
			}
		}
		.thumb {
			img {
				width: 100%;
				height: auto;
			}
		}

		.contents {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include setSpContents();
			padding-top: 20px;
			@include pcSmall {
				padding-left: 40px;
			}
		}

		.num {
			position: absolute;
			z-index: 2;
			width: 110px;
			top: -50px;
			img {
				width: 100%;
				height: auto;
			}
		}

		.title {
			color: $ml_blue;
			@include setSpFont(21, 44);
			font-weight: 700;
			margin-bottom: 20px;
			
		}

		.mod-basic-btn {
			width: 100%;
			a {
				margin: auto;
			}
		}

		.summary {
			font-size: 13px;
			line-height: 26px;
			margin-bottom: 33px;
		}
	}
}

// 
// 物件情報
// 
.top-property-list {
	position: relative;
	z-index: $zIndex2;
	margin-bottom: 152px;

	&__contents {
		@include setPcContents();
	}

	&__title {
		font-weight: 700;
		@include setSpFont(24, 44);
		padding-bottom: 16px;
		border-bottom: 1px solid #EBEBEB;
		margin-bottom: 40px;
		&.sub{
			font-size: 22px;
		}
	}

	&__item-list {
		.description {
			font-weight: 400;
			font-size: 13px;
			line-height: 190%;
			margin-bottom: 48px;
		}
	}

	.mod-basic-btn{ 
		margin-top: 72px;
	}
}

// 
// ニュース / イベント
// 
.top-news {
	position: relative;
	z-index: $zIndex2;
	margin-bottom: 153px;
	&__contents {
		@include setPcContents();
	}

	.mod-title__en {
		margin-bottom: 70px;
	}
	.mod-title__ja {
		margin-bottom: 42px;
	}

	
	.news-categories {
		display: none;
		
		/*
		margin-bottom: 40px;

		ul {
			display: flex;
		}
		
		li {
			margin-right: 22px;
		}
		a {
			color: $gray3;
			font-size: 16px;
			line-height: 30px;

			&.current {
				color: $ml_blue;
			}
		}
		*/
	}
	.news-list{
		padding-bottom: 20px;
	}

	@include spNewsList();
	
	.mod-basic-btn {
		a {
			margin: auto;
		}
	}
}