// 共通タイトル


@include pc {
	.js-scroll-animate.from-above,
	.scroll-animate-block .from-above {
		opacity: 0;
		filter: blur(5px);
		transform: translateY(-30px);
		transition: opacity 0.3s $easeOutExpo,
									transform 1.6s $easeOutExpo,
									filter 1.6s $easeOutExpo;
	}

	.js-scroll-animate.from-below,
	.scroll-animate-block .from-below {
		opacity: 0;
		filter: blur(5px);
		transform: translateY(30px);
		transition: opacity 0.3s $easeOutExpo,
									transform 1.6s $easeOutExpo,
									filter 1.6s $easeOutExpo;
	}


	.js-scroll-animate.from-side,
	.scroll-animate-block .from-side {
		opacity: 0;
		filter: blur(5px);
		transform: translateX(-20px);
		transition: opacity 0.3s $easeOutExpo,
									transform 1.6s $easeOutExpo,
									filter 1.6s $easeOutExpo;
	}

	@for $i from 0 to 20 {
		.animate-delay#{$i} {
			transition-delay: $i * 0.075s !important;
		}
	}

	.animated.js-scroll-animate.from-side,
	.animated.js-scroll-animate.from-below,
	.animated.js-scroll-animate.from-above,
	.animated.scroll-animate-block .from-side,
	.animated.scroll-animate-block .from-below,
	.animated.scroll-animate-block .from-above {
		opacity: 1;
		filter: blur(0);
		transform: translate(0, 0);
	}
}


@include sp {
	.js-scroll-animate.from-above,
	.scroll-animate-block .from-above {
		opacity: 0;
		transform: translateY(-30px);
		transition: opacity 1.4s $easeOutExpo,
									transform 1.6s $easeOutExpo,
	}

	.js-scroll-animate.from-below,
	.scroll-animate-block .from-below {
		opacity: 0;
		transform: translateY(30px);
		transition: opacity 1.4s $easeOutExpo,
									transform 1.6s $easeOutExpo,
	}


	.js-scroll-animate.from-side,
	.scroll-animate-block .from-side {
		opacity: 0;
		transform: translateX(-20px);
		transition: opacity 1.4s $easeOutExpo,
									transform 1.6s $easeOutExpo,
	}

	@for $i from 0 to 20 {
		.animate-delay#{$i} {
			transition-delay: $i * 0.075s !important;
		}
	}

	.animated.js-scroll-animate.from-side,
	.animated.js-scroll-animate.from-below,
	.animated.js-scroll-animate.from-above,
	.animated.scroll-animate-block .from-side,
	.animated.scroll-animate-block .from-below,
	.animated.scroll-animate-block .from-above {
		opacity: 1;
		transform: translate(0, 0);
	}
}

@import "./img";