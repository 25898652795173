// 企業情報ページ PC
.company-page {
	overflow: hidden;
	margin-bottom: -1px;
}

// 
// コンテンツ
// 
.company__basic-contents {
	display: flex;
	margin-top: 60px;
	margin-bottom: 0px;
	@include setPcContents();
	.picture {
		width: pcw(312);
		min-width: 312px;
		margin-right: pcw(72);
		img {
			width: 100%;
			height: auto;
		}
	}
	.contents {
		flex: 1;
	}
	.title {
		font-size: pcw(32);
		font-weight: 700;
		line-height: 1.8;
		margin-bottom: 30px;
	}

	.description {
		font-size: 14px;
		line-height: 2;
	}
	
	.message__name {
		margin-top: 30px;
		text-align: right;
		.position {
			display: block;
			font-size: 14px;
			line-height: 2;
			padding-bottom: 5px;
		}
		.name {
			display: block;
			font-size: 15px;
			line-height: 2;
			margin-left: 10px;
			img{
				width: 180px;
			}
		}
	}
	
	
}


/**
 * 会社概要
 **/



/**
 * アクセス
 **/
.company__access {
	position: relative;
	padding-left: pcw(200);
	padding-top: 55px;
	margin-bottom: 170px;
	@include setPcContents();
	.map {
		position: relative;
		margin-bottom: 40px;
		&__frame {
			position: relative;
			width: 100%;
			height: 0;
			padding-top: 70.25%;
		}
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}

	.title {
		font-size: 15px;
		line-height: 30px;
		font-weight: 700;
		margin-bottom: 10px;
	}
	.address {
		font-size: 14px;
		line-height: 1.8;
		margin-bottom: 10px;
	}
	.info {
		color: $gray1;
		font-size: 14px;
		line-height: 1.8;
		display: flex;
		.item {
			margin-right: 24px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

/**
 * CSR
 */
.company-page {
	.lead-text {
		padding-left: pcw(200);
		font-size: 15px;
		line-height: 1.8;
		@include setPcContents();
		margin-bottom: 70px;
	}
}

.company__csr {
	@include setPcContents();
	margin-bottom: 175px;
	.csr-title {
		font-weight: 700;
		font-size: pcw(32);
		line-height: 1.8;
		padding-bottom: pcw(30);
		border-bottom: 1px solid $gray4;
		margin-bottom: 63px;
	}

	.csr-article {
		padding-left: pcw(200);
		margin-bottom: 95px;
		.pic {
			border: 1px solid $gray3;
			margin-bottom: 30px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.meta {
			display: flex;
			margin-bottom: 14px;
		}
		.date {
			font-weight: 700;
			font-size: 12px;
			line-height: 30px;
			margin-right: 20px;
			padding-top: 5px;
		}
		.title {
			flex: 1;
			font-weight: 700;
			font-size: 24px;
			line-height: 44px;
		}
		.summary {
			font-size: 14px;
			line-height: 1.8;
		}
		.button {
			margin-top: 30px;
			display: flex;
			justify-content: flex-end;
		}
	}
 }
 
 
/**
 * 強みバナー
 **/

.business-banner{
	width: 100%;
	padding-top: 100px;
	img{
		width: 100%;
	}
}



.company__mvv-bg{
	//background-image: url(/assets/img/company/mvv-bg.png);
	//background-size: 100% auto;
	//background-repeat: no-repeat;
	padding-top: 180px;
}

body.mvv-bg,
body.mvv-bg:after{
	background-image: url(/assets/img/company/mvv-bg.png);
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: 0 1080px;
	background-color: transparent !important;
}
body.mvv-bg:after{
	background: none;
}
.company__mvv-contents{
	margin-top: 0px;
	padding-bottom: 160px;
	@include setPcContents();
	position: relative;
	
	.mvv-img{
		position: absolute;
		right: 0;
		width: 22.8vw;
		z-index: 0;
		img{
			width: 100%;
		}
	}
	
	&__mission{
		width: 35vw;
		.mvv-title{
			margin-bottom: 32px;
			img{
				width: 100%;	
			}
		}
		.mvv-subtitle{
			margin-bottom: 32px;
			font-size: 40px;
			font-weight: bold;
			letter-spacing: 0.5px;
			span{
				font-size: 48px;
				letter-spacing: 0.8px;
			}	
		}
		.mvv-lead{
			font-size: 15px;
			line-height: 1.9;		
		}
	}
	&__vision{
		position: relative;
		z-index: 1;
		width: 28.4vw;
		margin-left: 34.7vw;
		.mvv-title{
			margin-bottom: 32px;
			img{
				width: 100%;	
			}
		}
		.mvv-subtitle{
			margin-bottom: 32px;
			font-size: 40px;
			font-weight: bold;
			line-height: 1.3;
			text-indent: -0.75em;
			span{
				font-size: 48px;
			}	
		}
		.mvv-lead{
			font-size: 15px;
			line-height: 1.9;		
		}
	}
	&__value{
		//width: 50.3vw;
		margin-top: -55px;
		.mvv-title{
			width: 28.4vw;
			margin-bottom: 52px;
			img{
				width: 100%;	
			}
		}
		.mvv-value{
			li{
				display: flex;
				gap: 16px;
				margin-bottom: 32px;
				
				span.num{
					width: 12px;
					font-size: 11px;
					color: $ml_sky;
					font-weight: bold;
				}	
				span.text{
					font-size: 24px;
					font-weight: bold;	
				}
			}
			
		}
	}

}
