// ビジネス SP
.business-page {
	overflow: hidden;
	margin-bottom: -1px;
	
	.section-title {
		font-weight: bold;
		font-size: 23px;
		line-height: 1.5;
		border-bottom: 1px solid $gray4;
		padding-bottom: 13px;
	}
}
.contact-link {
	a {
		display: block;
		background: #000;
		&:hover {
			img {
				opacity: 0.7;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
		transition: opacity 0.6s $easeOutExpo;
	}
}
/**
 * マンション経営をお考えの方へ
 */
.mm-sec-contents {
	margin: 46px 35px 110px;
	.section-title {
		margin-bottom: 40px;
	}
	.heading {
		color: $ml_blue;
		font-weight: bold;
		font-size: 19px;
		line-height: 1.8;
		margin-bottom: 55px;
	}
	.subhead {
		font-weight: bold;
		font-size: 16px;
		line-height: 1.6;
		margin-bottom: 20px;
	}
	.pic {
		img {
			width: 100%;
			height: auto;
		}
		&.table {
			margin-left: -35px;
			margin-right: -35px;
			overflow-x: auto;
		}
		.table__scroll {
			width: 700px;
			padding: 0 35px 35px;
		}
	}
	.pic1 {
		margin-bottom: 70px;
	}
	.pic2 {
		margin-bottom: 70px;
	}
	.note {
		font-size: 13px;
		line-height: 26px;
		margin-bottom: 42px;
	}
	.pic4 {
		margin-bottom: 74px;
	}
	.merit {
		background: url(/assets/img/business/img_mm5.png) no-repeat 0 0;
		background-size: 46% auto;
		&-title {
			margin-left: 50%;
			display: inline-block;
			color: $ml_sky;
			font-weight: 700;
			line-height: 80px;
			margin-bottom: 96px;
			border-bottom: 1px solid $gray4;
			.num {
				font-size: 36px;
			}
			.text {
				font-size: 20px;
			}
		}
		&-contents {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		&-block {
			position: relative;
			width: 100%;
			margin: 0 0 66px;
			padding: 38px 26px;
			background: $gray5;
			border: 1px solid $gray4;
			
			.merit-num {
				color: $ml_sky;
				position: absolute;
				text-align: center;
				top: -30px;
				left: 50%;
				transform: translateX(-50%);
				span {
					display: block;
				}
				.text {
					font-weight: bold;
					font-size: 11px;
					line-height: 20px;
				}
				.num {
					font-weight: bold;
					font-size: 32px;
					line-height: 34px;
				}
			}
			.title{
				text-align: center;
				font-weight: bold;
				font-size: 20px;
				line-height: 36px;
				margin-bottom: 5px;
			}

			.summary {
				text-align: left;
				font-size: 13px;
				line-height: 26px;
			}
		}
	}
	.risk {
		margin-top: -20px;
	}
	.risk-block {
		border-top: 1px solid $gray4;
		border-bottom: 1px solid $gray4;
		margin-bottom: -1px;
		&.open {
			.risk__q {
				&::after {
					transform: rotate(90deg) scaleX(0);
				}
			}
			.risk__a {
				height: auto;
				opacity: 1;
			}
		}
	}
	
	.risk__q {
		position: relative;
		display: flex;
		align-items: center;
		padding: 24px 0;
		padding-right: 32px;
		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			width: 16px;
			height: 1px;
			background: $black;
		}
		&::after {
			transform: rotate(90deg);
			transition: transform 0.6s $easeOutExpo;
		}
		.num-box {
			color: $white;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			background: $ml_sky;
			.num,
			.next {
				font-weight: bold;
				line-height: 22px;
			}
			.num{
				font-size: 20px;
			}
			.next {
				font-size: 10px;
			}
		}
		.summary {
			flex: 1;
			font-weight: bold;
			font-size: 20px;
			line-height: 36px;
			margin-left: 20px;
		}
	}
	.risk__a {
		height: 0;
		opacity: 0;
		overflow: hidden;
		
		.inner {
			font-size: 13px;
			line-height: 26px;
			padding-bottom: 22px;
		}
	}

	.faq-block {
		.faq-q {
			color: $ml_blue;
			font-weight: bold;
			font-size: 20px;
			line-height: 36px;
			margin-bottom: 12px;
			text-indent: -28px;
			padding-left: 28px;
		}
		.faq-a {
			.title {
				font-weight: bold;
				font-size: 15px;
				line-height: 28px;

				margin-bottom: 4px;
				text-indent: -26px;
				padding-left: 26px;
				span {
					font-size: 20px;
				}
			}
			p {
				font-size: 12px;
				line-height: 24px;
				padding-left: 26px;
			}
		}
		.mod-basic-btn {
			margin-top: 22px;
			a {
				width: 100%;
			}
		}
		&.q1{
			margin-bottom: 70px;
			.faq-block__img {
				width: 48%;
				margin: 16px auto 0;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q2 {
			margin-bottom: 70px;
			.faq-block__img {
				width: 48%;
				margin: 16px auto 0;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q3 {
			margin-bottom: 40px;
			.faq-block__img {
				width: 52%;
				margin: 16px auto 0;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q4 {
			margin-bottom: 60px;
			
			.faq-block__img {
				width: 65%;
				margin: 16px auto 0;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		&.q5{
			margin-bottom: 25px;
			.faq-block__img {
				width: 58%;
				margin: 16px auto 0;
				img {
					width: 100%;
					height: auto;
				}
			}
			
		}
		&.q6 {
			margin-bottom: 64px;
			
			.faq-block__img {
				width: 44%;
				margin: 16px auto 0;
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
	.faq-table-data {
		margin-top: 36px;
		margin-bottom: 95px;
		.data {
			.meta {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;
			}
			.title {
				font-weight: bold;
				font-size: 13px;
				line-height: 26px;
			}
			.unit {
				color: $gray1;
				font-size: 12px;
				line-height: 24px;
			}
			.table {
				margin-left: -35px;
				margin-right: -35px;
				overflow-x: auto;
				&-scroll {
					width: 490px;
					padding: 0 35px 35px;
				}
				img {
					width: 100%;
					height: auto;
				}
			}
			.notes {
				color: $gray1;
				font-size: 13px;
				line-height: 26px;
			}
		}
		.image {
			margin-top: 25px;
			img {
				width: 100%;
				height: auto;
			}
			
		}
	}
}


/**
 * メインランドグループの強み
 */
.adv-sec-contents {
	margin: 46px 35px 110px;
	.section-title {
		margin-bottom:  35px;
		.title-notes {
			display: block;
			font-weight: normal;
			color: $gray1;
			font-size: 15px;
			line-height: 38px;
		}
	}
	.heading {
		color: $ml_blue;
		font-weight: bold;
		font-size: 20px;
		line-height: 36px;
		margin-bottom: 10px;
	}
	.summary {
		font-size: 13px;
		line-height: 1.9;
		margin-bottom: 45px;
	}
	.data-box {
		display: flex;
		flex-wrap: wrap;
		.data-item {
			text-align: center;
			width: 50%;
			margin-bottom: 30px;
			@media screen and (max-width: 320px) {
				width: 70%;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.label {
			font-size: 12px;
			line-height: 26px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			min-height: 32px;
			min-width: 90px;
			padding: 5px 10px;
			border: 1px solid $gray4;
			margin-bottom: 4px;
			span {
				font-size: 7px;
				line-height: 18px;
				padding-bottom: 3px;
				margin-left: 3px;
			}
		}
		.data {
			color: $ml_blue;
			font-weight: bold;
			text-align: center;
			font-size: 16px;
			line-height: 48px;
			.num {
				font-size: 34px;
			}
		}
	}

	.data-notes {
		text-align: right;
		color: $gray1;
		font-size: 13px;
		line-height: 26px;
	}
	.data-image {
		margin-top: 43px;
		margin-left: -35px;
		margin-right: -35px;
		width: auto;
		
		img {
			width: 100%;
			height: auto;
		}
	}

	.message {
		font-size: 13px;
		line-height: 2;
		text-align: center;
		padding: 24px 26px 24px;
		background: $gray5;
		border: 1px solid $gray4;
		margin-bottom: 70px;
	}

	&.about-section {
		.table {
			margin-left: -35px;
			margin-right: -35px;
			margin-top: 44px;
			margin-bottom: 35px;
			overflow-x: auto;
			&-scroll {
				width: 700px;
				padding: 0 35px 35px;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		.notes {
			color: $gray1;
			font-size: 12px;
			line-height: 1.7;
			margin-bottom: 64px;
		}
		.about-box {
			border-bottom: 1px solid $gray4;
			margin-bottom: 30px;
			.subhead {
				font-weight: bold;
				font-size: 15px;
				line-height: 30px;
				margin-bottom: 10px;
			}
			.summary {
				margin-bottom: 20px;
			}
		}
	}
	&.contract-section {
		.image {
			margin: 38px 0 48px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.table {
			margin-left: -35px;
			margin-right: -35px;
			overflow-x: auto;
			&-scroll {
				width: 700px;
				padding: 0 35px 35px;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		.info {
			text-align: center;
			line-height: 30px;
			font-size: 15px;
			margin-bottom: 25px;
			.blue {
				color: $ml_blue;
			}
			.num {
				font-weight: bold;
				font-size: 36px;
			}
			.unit {
				font-weight: bold;
				font-size: 26px;
			}
		}
		.notes {
			text-align: center !important;
			text-align: left;
			color: $gray1;
			font-size: 12px;
			line-height: 26px;
			margin-bottom: 42px;
		}
		
	}
	&.follow-section {
		margin-bottom: 120px;
		overflow: hidden;
		.image {
			margin: 44px 0 22px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.lead-title {
			font-weight: bold;
			font-size: 20px;
			line-height: 36px;
			margin-bottom: 18px;
		}
		.summary {
			margin-bottom: 45px;
		}
		.articles {
			margin-bottom: 80px;
			&-item {
				margin-bottom: 43px;
				img {
					width: 100%;
					height: auto;
				}
			}
			a {
				display: block;
			}
			.thumb {
				margin-bottom: 16px;
			}
			.title {
				font-weight: bold;
				font-size: 15px;
				line-height: 28px;
				margin-bottom: 10px;
			}
			.lead {
				font-size: 13px;
				line-height: 1.9;
				span{
					color: #666;
					font-size: 11px;
				}
			}
		}
	}
}

/**
 * オーナデータ
 */
.owner-data__graph {
	margin: 0 35px 108px;
	.title {
		color: $ml_blue;
		font-weight: bold;
		font-size: 20px;
		line-height: 36px;
		margin-bottom: 0px;
	}

	.graphs {
		.item {
			margin-bottom: 40px;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
}

.owner-data__voice {
	@include setSpContents;
	margin-bottom: 140px;

	.section-title {
		margin-bottom: 40px;
	}

	.block {
		padding-bottom: 66px;
		border-bottom: 1px solid $gray4;
		margin-bottom: 40px;
		&:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}
	}

	.title {
		color: $ml_blue;
		font-weight: bold;
		font-size: 20px;
		line-height: 36px;
		text-align: center;
		margin-bottom: 25px;
	}

	.data {
		font-size: 12px;
		line-height: 24px;
		text-align: center;
		background: $gray5;
		border: 1px solid $gray4;
		padding: 15px 5px 17px;
		margin-bottom: 15px;
	}
	.name {
		font-weight: bold;
		font-size: 15px;
		line-height: 30px;
		margin-bottom: 4px;
	}
	.summary {
		font-size: 12px;
		line-height: 24px;
	}
}

.owner-data__interview {
	margin: 0 35px 120px;

	.section-title {
		margin-bottom: 50px;
	}

	.contents {
		margin-bottom: 34px;
		&-inner {
			position: relative;
			margin-bottom: 35px;
		}
		
		.thumb {
			width: 100%;
			height: auto;
		}

		@include moviePlay(0.75, false);

		.youtube {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			z-index: 1;
			overflow: hidden;
			&-contents {
				width: 100%;
				height: 0;
				padding-bottom: 56.25%;
			}
			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

/**
 * メインステージ > コンセプト
 **/
 .main-stage-concept {
	@include setSpContents;
	margin-bottom: 104px;

	.section-title {
		margin-bottom: 48px;
	}

	.main-message-block {
		margin-bottom: 56px;
		.image {
			margin-left: -36px;
			margin-right: -36px;

			@media (max-width: $spDesignWidth2 * 1px){
				margin-left: -20px;
				margin-right: -20px;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
		.meta {
			margin-top: 24px;
		}
		.summary {
			font-weight: 400;
			font-size: 13px;
			line-height: 190%;
		}
	}

	.massages-block {
		width: 100%;
		margin-bottom: 48px;
		.image {
			margin-bottom: 16px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.title {
			font-weight: 700;
			font-size: 18px;
			line-height: 170%;
			margin-bottom: 10px;
		}
		.summary,
		.notes {
			font-weight: 400;
			font-size: 13px;
			line-height: 175%;
		}
		.notes {
			margin-top: 4px;
			color: $gray1;
			text-indent: -1.2rem;
			padding-left: 1.3rem;
			&::before {
				content: "※"
			}
		}
	}
}


/**
 * 物件一覧リスト
 **/
.property-list-page {
	&__contents {
		@include setSpContents;
		margin-bottom: 152px;
	}
	
	.mod-property-item {
		margin-bottom: 48px;
		.link {
			padding-bottom: 20px;
		}
		.name {
			font-weight: 500;
			font-size: 20px;
			margin-bottom: 12px;
		}
	}

	&__contents {
		.more {
			margin-top: 64px;
			.link {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 72px;
				background: $gray4;
				&::before,
				&::after {
					position: absolute;
					content: "";
					width: 16px;
					height: 1px;
					top: 50%;
					right: 24px;
					background: $ml_blue;
				}
				&::after {
					transform: rotate(90deg);
				}
			}
			.text {
				font-weight: 700;
				font-size: 15px;
				line-height: 30px;
				letter-spacing: 0.02em;
				color: $ml_blue;
			}
		}
	}
}

/**
 * 物件概要
 */
 .pp-page-description {
	font-weight: 400;
	font-size: 13px;
	line-height: 190%;
	margin-bottom: 48px;
	@include setSpContents;
}
.pp-main-property {
	position: relative;
	margin-bottom: 104px;
	.images {
		margin-bottom: 104px;
	}
	.main-images {
		position: relative;
		margin-bottom: 17px;
		.image {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			z-index: 1;
			width: 100%;
			aspect-ratio: 2045 / 1414;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.current {
			position: relative;
			z-index: 2;
			opacity: 1;
		}
	}
	.thumb-images {
		display: flex;
		flex-wrap: wrap;
		@include setSpContents;
		.image {
			margin: 7px 3px 0;
			width: calc(25% - 6px);
			a {
				position: relative;
				display: block;
				width: 100%;
				aspect-ratio: 2045 / 1414;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					box-sizing: border-box;
					border: 1px solid rgba($white, 0);
					transition: 0.6s border $easeOutExpo;
				}
				&.current {
					&::after {
						border: 1px solid $ml_sky;
					}
				}
			}
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
	.overview {
		@include setSpContents;
		.title {
			font-weight: 700;
			font-size: 24px;
			line-height: 44px;
			padding-bottom: 16;
			border-bottom: 1px solid $gray4;
			margin-bottom: 40px;
		}
		.spec-list {
			margin-bottom: 48px;
		}
		.spec {
			display: flex;
			font-weight: 400;
			font-size: 13px;
			line-height: 175%;
			margin-bottom: 8px;
			.label {
				color: $ml_blue;
				flex-shrink: 0;
				width: 80px
			}
		}
		.mod-basic-btn {
			margin-top: 24px;
			a {
				margin: auto;
				max-width: 350px;
				width: 100%;
			}
		}
	}
}

/**
 * 物件詳細ページ共通
 */
.pp-detail-section {
	@include setPcContents();
	margin-bottom: 104px;

	.section-title {
		margin-bottom: 40px;
	}
}

/**
 * 物件詳細ページ > 間取り
 */
.pp-floor-plan {

	.plan-list {
		position: relative;
		overflow: hidden;
		width: 100%;
	}
	.slide-area-wrapper {
		overflow: hidden;
		width: spw(300);
		margin: auto;
	}
	.slide-area {
		display: flex;
		margin-left: -10px;
	}

	.plan-item {
		flex-shrink: 0;
		width: spw(300);
		margin-left: 10px;
		.image {
			margin-bottom: 16px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.name,
		.spec {
			width: spw(210);
			margin-left: auto;
			margin-right: auto;
		}
		.name {
			font-weight: 700;
			font-size: 15px;
			line-height: 175%;
			margin-bottom: 8px;
		}
		.spec {
			margin-bottom: 2px;
			font-weight: 400;
			font-size: 13px;
			line-height: 175%;
			display: flex;
			dt {
				flex-shrink: 0;
				width: 100px;
				color: $ml_blue;
			}
		}
	}

	.js-prev,
	.js-next {
		position: absolute;
		z-index: 2;
		top: 45%;
		width: 32px;
		height: 32px;
		transform: translateY(-50%);
		a {
			position: relative;
			z-index: 2;
			display: block;
			width: 0;
			height: 0;
			border: 16px solid transparent;
			transition: border 0.6s $easeOutExpo
		}
	}
	.js-prev {
		left: 0;
		a {
			border-right-color: $gray4;
		}
	}
	.js-next {
		right: 0;
		a {
			border-left-color: $gray4;
		}
	}
	.js-enabled-next {
		.js-next {
			a {
				border-left-color: $black;
				&:hover {
					border-left-color: $ml_sky;
				}
			}
		}
	}
	// 戻るボタンの有効化
	.js-enabled-prev {
		.js-prev {
			a {
				border-right-color: $black;
				&:hover {
					border-right-color: $ml_sky;
				}
			}
		}
	}
}
/**
 * 物件詳細ページ > パノラマ
 */
.pp-panorama {
	.panorama-item {
		margin-bottom: 40px;
		.title {
			font-weight: 500;
			font-size: 15px;
			line-height: 175%;
			margin-bottom: 16px;
		}
		.iframe {
			position: relative;
			padding-top: 62%;
			height: 0;
		}
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
}
/**
 * 物件詳細ページ > ロケーション
 */
.pp-location {
	@include spImageGallery;
}

/**
 * 物件詳細ページ > お問い合わせ
 */
.pp-contact {
	.description {
		font-weight: 400;
		font-size: 13px;
		line-height: 190%;
		margin-bottom: 40px;
	}
	.link-item {
		margin-bottom: 4px;
		img {
			width: 100%;
			height: auto;
		}
	}
}