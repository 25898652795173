// ニュース SP

.news-page {
	position: relative;
	z-index: $zIndex2;
	padding-top: 64px;
	margin-bottom: -1px;
	overflow: hidden;

	.news-categories {
		padding-bottom: 16px;
		border-bottom: 1px solid $gray4;
		margin-bottom: 48px;
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			li{
				display: block;
				width: 25%;
				text-align: center;
			}
		}
		a {
			position: relative;
			color: $gray3;
			font-size: 16px;
			line-height: 30px;
			padding-bottom: 2px;

			&.current {
				color: $ml_blue;
				&::after {
					position: absolute;
					content: "";
					bottom: 0;
					left: 0;
					height: 1px;
					width: 100%;
					background: $ml_blue;
				}
			}
		}
	}
}

/**
 * ニューストップ
 */ 
.news-top {
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 50px;
	
	@include spNewsList();
	
	.news-item {
		&.event {
			.tag {
				background: #005B98;
			}
		}
		&.news {
			.tag {
				background: #34426A;
			}
		}
		&.recruit {
			.tag {
				background: #1E1B19;
			}
		}
		&.csr {
			.tag {
				background: #396082;
			}
		}
		&.seminar {
			.tag {
				background: #393982;
			}
		}
		&.info {
			.tag {
				background: #356A78;
			}
		}
		&.pr {
			.tag {
				background: #473578;
			}
		}
	}

	.more {
		margin-top: 50px;
		a {
			margin: auto;
			position: relative;
			display: block;
			width: 40px;
			height: 40px;
			&::before,
			&::after {
				position: absolute;
				content: "";
				width: 100%;
				height: 1px;
				top: 50%;
				background: $black;
			}
			&::after {
				transform: rotate(90deg);
			}
		}		
	}
	.noData{
		padding-top: 30px;
		padding-bottom: 0px;
		text-align: center;
		font-size: 15px;
		line-height: 1.9;
	}
}


/**
 * ニュース詳細
 */ 
.news-detail {
	margin-left: 35px;
	margin-right: 35px;
	margin-bottom: 50px;

	.news-article {
		.meta {
			display: flex;
			align-items: center;
			margin-bottom: 22px;
			
			.tag {
				color: $black;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.04em;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 72px;
				height: 22px;
				margin-right: 12px;
				background: $gray4;
			}

			.date {
				color: $gray4;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.02em;
			}
			.tag {
				color: $white;
			}
			.date {
				color: $black;
			}
			a {
				&::before {
					background: $black;
				}
				&::after {
					border-left-color: $black;
				}
			}
			@include tagColor;
		}
		.post-title {
			font-size: 24px;
			line-height: 1.7;
			margin-bottom: 33px;
		}
		.contents {
			margin-bottom: 55px;
			word-wrap: break-word;
			font-size: 13px;
			line-height: 1.8;
			
			// エディータコンテンツ
			
			p {
				margin-bottom: 2em;
			}
			strong{
				font-weight: bold;
			}
			em{
				font-style: italic;
			}
			img{
				max-width: 100%;
				height: auto;
			}
			
			ul{
				margin-bottom: 2em;
				li{
					padding-left: 1em;
					margin-bottom: 8px;
					line-height: 1.6;
					&::before{
						content: "・";
						position: relative;
						margin-left: -1em;
					}
				}
			}
			ol{
				margin-bottom: 2em;
				padding-left: 1.3em;
				li{
					margin-bottom: 8px;
					list-style-type: decimal;
					line-height: 1.6;
				}
			}
			table{
				margin-bottom: 2em;
				border: 1px solid #CCC;
				th{
					padding: 5px;
					background: #CCC;
					font-weight: bold;
				}	
				td{
					border: 1px solid #CCC;
					padding: 5px;
				}
				p{
					margin-bottom: 0px;
				}
			}
			
			hr{
				margin: 3em 0;
			}
			
			a {
				color: $ml_sky;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}

		.image {
			img {
				max-width: 100%;
				width: auto;
				height: auto;
			}
			margin-bottom: 30px;
		}

		.back-button {
			margin-top: 84px;
			.mod-basic-btn {
				a {
					max-width: 300;
					width: 100%;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
	.image-gallery {
		.image {
			margin-bottom: 0;
		}
		@include spImageGallery();
	}
}