// 共通フッター SP

.site-footer {
	background: $black;
	padding-top: 172px;
	padding-bottom: 44px;
	@include setSpContents();
	&.min{
		padding-top: 40px;
	}
	
	&__logo {
		img {
			width: 255px;
		}
		margin-left: -5px;
		margin-bottom: 44px;
	}
	&__sns {
		margin-bottom: 40px;
		.sns {
			&-list {
				display: flex;
				margin-left: -2px;
			}
			&-item {
				margin-right: 23px;
			}
		}
	}
	.js-sp-toggle {
		.js-sp-toggle__list {
			height: 0;
			overflow: hidden;
		}
	}
	.site-menu {
		&__title {
			margin-bottom: 12px;
			a,
			.no-link {
				position: relative;
				display: block;
				color: $white;
				font-size: 14px;
				line-height: 28px;
				font-weight: 700;
				padding-bottom: 9px;
				border-bottom: 1px solid $white;
			}
			a {
				&::after {
					content: "";
					position: absolute;
					right: -6px;
					bottom: 10px;
		
					border: 6px solid transparent;
					border-left-color: $white;
					transform: rotate(45deg);
				}
			}
		}
		&__list {
			margin-left: 18px;
			margin-bottom: 18px;
		}
		&__link,
		&__no-link {
			color: $gray3;
			font-size: 12px;
			line-height: 26px;
		}
	}
	
	.client-menu {
		display: flex;
		flex-wrap: wrap;
		margin-top: 46px;
		margin-bottom: 26px;

		.mod-basic-btn {
			width: 100%;
			margin-bottom: 16px;
			a {
				width: 100%;
			}
		}
	}

	.policy-menu {
		margin-bottom: 44px;
		&__list {
			display: flex;
		}
		&__item {
			margin-right: 24px;
		}
		&__link {
			color: $gray2;
			font-size: 11px;
			line-height: 22px;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.copyright {
		a img {
			width: 168px;
		}

		small {
			display: block;
			margin-top: 14px;
		}
	}
}

.page-top-scroll {
	display: none;
}