
.plan-modal {
	position: fixed;
	z-index: $zIndex11;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(#000, 0.8);
	padding: 40px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: 0.6s $easeOutExpo opacity;
	.js-modal-open & {
		opacity: 1;
		pointer-events: auto;
	}
	.bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		cursor: default;
	}
	.close {
		position: absolute;
		right: 0;
		top: -40px;
		width: 30px;
		height: 30px;
		.top,
		.bottom {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			height: 1px;
			background: $white;
		}
		.top {
			transform: rotate(30deg)
		}
		.bottom {
			transform: rotate(-30deg)
		}
	}
	.content {
		position: relative;
		max-width: 550px;
		max-height: 700px;
		width: 100%;
		height: 80vh;
		margin: auto;
		padding: 20px;
		display: flex;
		background: $white;
	}

	.slide-area-wrapper {
		position: relative;
		overflow: hidden;
		max-width: 410px;
		width: 100%;
		height: 100%;
		margin: auto;
	}

	.slide-area {
		position: relative;
		display: flex;
		width: 9999px;
		height: 100%;
	}
	.plan-item {
		position: relative;
		max-width: 410px;
		width: 80vw;
		height: 100%;
		padding-bottom: 30px;
		.image {
			position: relative;
			width: 100%;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.label {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			text-align: center;
			font-weight: 700;
			font-size: 13px;
			line-height: 175%;
		}
	}
	.js-prev,
	.js-next {
		position: absolute;
		z-index: 2;
		top: 45%;
		width: 32px;
		height: 32px;
		transform: translateY(-50%);
		a {
			position: relative;
			display: block;
			width: 0;
			height: 0;
			border: 16px solid transparent;
			transition: border 0.6s $easeOutExpo
		}
	}
	.js-prev {
		left: 10px;
		a {
			border-right-color: $gray4;
		}
	}
	.js-next {
		right: 10px;
		a {
			border-left-color: $gray4;
		}
	}
	.js-enabled-next {
		.js-next {
			a {
				border-left-color: $black;
				&:hover {
					border-left-color: $ml_sky;
				}
			}
		}
	}
	// 戻るボタンの有効化
	.js-enabled-prev {
		.js-prev {
			a {
				border-right-color: $black;
				&:hover {
					border-right-color: $ml_sky;
				}
			}
		}
	}
}