// 共通ヘッダー　PC
body {
	padding-top: 96px;
	&.top-page {
		padding-top: 0;
	}
}
.site-header {
	position: fixed;
	z-index: $zIndex10 - 2;
	left: 0;
	top: 0;
	right: 0;
	height: 96px;
	background: $white;
	transition: transform 1.2s $easeOutExpo,
								height 0.4s $easeOutExpo;
	transform: translateY(0);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	&.hide {
		transform: translateY(-100%) !important;
		transition-delay: 0;
	}
	&.is-hover {
		height: 130px;
	}
	&__logo {
		position: absolute;
		left: 36px;
		top: 32px;
	}

	&__menu {
		position: absolute;
		right: 32px;
		top: 28px;
		height: 40px;
		display: flex;
		align-items: center;
	}

	.site-menu {
		&__list {
			height: 40px;
			display: flex;
			align-items: center;
		}
		&__item {
			position: relative;
			padding: 0 15px;
			&:before {
				content: "";
				position: absolute;
				top: 3px;
				bottom: 0;
				right: 0;
				width: 1px;
				height: 13px;
				margin: auto;
				background: $black;
			}
			&::after {
				content: "";
				position: absolute;
				left: 15px;
				right: 15px;
				bottom: -3px;
				height: 1px;
				background: $ml_blue;
				transform: scaleX(0);
				transform-origin: right top;
				transition: transform 0.8s $easeOutExpo;
			}
			&:last-child {
				&::before {
					display: none;
				}
			}
			&:hover {
				.site-sub-menu {
					opacity: 1;
					pointer-events: inherit;
					transform: translateX(0);
				}
				// color: $ml_blue;
				&::after {
					transform: scaleX(1);
					transform-origin: left top;
				}
			}
		}
		&__link {
			position: relative;
			font-size: 13px;
			font-weight: 700;
			line-height: 14px;
			display: inline-flex;
			padding-top: 5px;
			height: 25px;
			// &::after {
			// 	content: "";
			// 	position: absolute;
			// 	left: 0;
			// 	bottom: -3px;
			// 	width: 100%;
			// 	height: 1px;
			// 	background: $ml_blue;
			// 	transform: scaleX(0);
			// 	transform-origin: right top;
			// 	transition: transform 0.8s $easeOutExpo;
			// }
			.inner {
				display: flex;
				align-items: center;
			}

			&:hover {
				color: $ml_blue;
				&::after {
					transform: scaleX(1);
					transform-origin: left top;
				}
			}
		}
		.slash {
			position: relative;
			font-size: 14px;
			top: -1px;
			padding: 5px;
		}
	}
	.site-sub-menu {
		position: absolute;
		top: 100%;
		left: 0;
		padding-top: 20px;
		padding-left: 15px;
		pointer-events: none;
		opacity: 0;
		transform: translateX(-5px);
		transition: all 0.6s $easeOutExpo;
		&__list {
			display: flex;
		}
		&__item {
			white-space: nowrap;
			flex-shrink: 1;
			margin-right: 20px;
		}
		&__link {
			position: relative;
			//color: $gray3;
			font-size: 12px;
			line-height: 1.8;
			padding: 5px 0;
			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 2px;
				width: 100%;
				height: 1px;
				background: $ml_blue;
				transform: scaleX(0);
				transform-origin: right top;
				transition: transform 0.8s $easeOutExpo;
			}
			&:hover {
				color: $ml_blue;
				&::after {
					transform: scaleX(1);
					transform-origin: left top;
				}
			}
		}
	}

	.client-menu {
		display: flex;
		.mod-basic-btn {
			margin-left: 16px;
		}
	}
}
.hamburger {
	display: none;
}

// 1370  ~ 1281
// 1. フォントサイズを小さく
@media screen and (max-width: 1370px) and (min-width: 1281px) {
	.site-header {
		.site-menu {
			&__link {
				font-size: 11px;
			}
		}
		.site-sub-menu {
			padding-left: 15px;
			&__link {
				font-size: 11px;
			}
		}
	}
}

// 1280  ~ 1101
// 1. ロゴを相対的に縮める 1100pxの段階で200pxになるように
// 2. メニューの間隔縮める
// 3. メニューとボタンのかんかん縮める
@media screen and (max-width: 1280px) and (min-width: 1101px) {
	.site-header {
	
		&__logo {
			img {
				width: (200/1100) * 100vw;
			}
		}
	
		.site-menu {
			&__item {
				padding-right: 10px;
				padding-left: 10px;
				&::after {
					left: 10px;
					right: 10px;
				}
				&:last-child {
					padding-right: 5px;
					&::before {
						display: none;
					}
				}
			}
			&__link {
				font-size: 11px;
			}
		}
		.site-sub-menu {
			padding-left: 10px;
			&__link {
				font-size: 11px;
			}
		}
	}
}

// 1230  ~ 1101
// 1. 「/ 請求資料」非表示
@media screen and (max-width: 1230px) and (min-width: 1101px) {
	.site-header {
		.site-menu {
			&__item {
				.pc-small-none {
					display: none;
				}
			}
		}
		.site-sub-menu {
			padding-left: 5px;
			&__link {
				font-size: 11px;
			}
		}
	}
}

// 1170  ~ 1101
// 1. メニューの「|」非表示
// 2. メニューの間隔を相対的に縮める(1100pxの段階で10pxになるように）
@media screen and (max-width: 1170px) and (min-width: 1101px) {
	.site-header {
	
		.site-menu {
			&__item {
				padding: 0 ((5/1100) * 100vw);
				&::before {
					display: none;
				}
			}
		}
	}
}

// 1100px ~ 769px
// ロゴ + ハンバーガーにする
@media screen and (max-width: 1100px) {
	.site-header {
		height: 68px;
		&__logo {
			top: 22px;
			img {
				width: 180px;
			}
		}

		&__menu {
			display: none;
		}
	}
	.hamburger {
		display: block;
		top: 14px;
		right: 22px;
	}
}

@media screen and (min-width: 1101px) {
	.sp-site-header {
		display: none !important;
	}	
}
