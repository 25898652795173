// 採用ページ PC

.recruit-page {
	position: relative;
	z-index: $zIndex2;
	//padding-top: pcw(102);
	margin-bottom: -1px;
	overflow: hidden;
	background: $white;

	.lead-text {
		padding-left: pcw(200);
		font-size: 15px;
		line-height: 30px;
		@include setPcContents();
		margin-bottom: 70px;
		a{
			img{
				max-width: 321px;
			}
		}
	}

	.section-title {
		font-weight: bold;
		font-size: 32px;
		line-height: 58px;
		border-bottom: 1px solid $gray4;
		padding-bottom: 30px;
	}
}

aside.recruit__nav {
	.contents {
		display: flex;
	}
	.link {
		position: relative;
		display: block;
		width: 50%;
		@include imgHover();
		img {
			width: 100%;
			height: auto;
		}
		.text {
			color: $white;
			font-weight: bold;
			font-size: 24px;
			line-height: 44px;
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

/**
 * 採用トップページ
 */
.recruit-insta-btn {
	@include setPcContents();
	position: relative;
	a{
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		margin-top: calc( -120px - 1vw);
		font-size: 13px;
		line-height: 20px;
	}
	a:hover{
		color: #005B98;
	}
}

.recruit-message-section {
	@include setPcContents();
	margin-top: 40px;
	margin-bottom: 84px;
	.contents {
		padding-left: pcw(200);
	}
	.title {
		font-weight: bold;
		font-size: 32px;
		line-height: 58px;
		margin-bottom: 30px;
	}
	.message {
		font-size: 14px;
		line-height: 1.8;
		margin-bottom: 23px;
	}
	.by {
		text-align: right;
		line-height: 48px;
		.label {
			font-size: 13px;
			line-height: 48px;
		}
		.text {
			line-height: 48px;
			font-size: 22px;
		}
	}
}

.recruit-movie {
	@include setPcContents();
	margin-bottom: 145px;
	&__contents {
		position: relative;
		margin: auto;
	}
	.thumb {
		width: 100%;
		height: auto;
	}
	
	@include moviePlay(1, true);
	
	.youtube {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 1;
		overflow: hidden;
		&-contents {
			// position: absolute;
			// left: 0;
			// top: 0;
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.recruitment-section {
	@include setPcContents();
	margin-bottom: 140px;
	.section-title {
		margin-bottom: 64px;
	}
}

.recruit-topics-section {
	@include setPcContents();
	@include pcNewsList();
	margin-bottom: 100px;
	.section-title {
		margin-bottom: 60px;
	}
	.news-list {
		padding-left: pcw(200);
	}
	.news-item {
		a {
			display: flex;
			align-items: flex-start;
		}

		.meta {
			padding-top: 5px;
			margin-bottom: 0;
			margin-right: 20px;
		}
		.title {
			flex: 1;
		}
	}
	.mod-basic-btn {
		margin-top: 64px;
		display: flex;
		justify-content: flex-end;
	}
}
.recruit-interview-section {
	@include setPcContents();
	@include pcNewsList();
	margin-bottom: 145px;
	.section-title {
		padding-top: 20px;
		margin-bottom: 60px;
	}
	aside.recruit__interview {
		.contents {
			display: flex;
			gap:20px;
		}
		.link {
			position: relative;
			display: block;
			width: 50%;
			@include imgHover();
			img {
				width: 100%;
				height: auto;
			}
			.text{
				font-size: 14px;
				padding: 18px 0 0 0;
			}
		}
	}
}





.recruit-data-section {
	@include setPcContents();
	margin-bottom: 160px;
	.section-title {
		margin-bottom: 64px;
	}
	.contents {
		padding-left: pcw(200);
	}
	.graph {
		img {
			width: 100%;
			height: auto;
		}
	}
}

.other-section {
	@include setPcContents();
	margin-bottom: 145px;
	.section-title {
		margin-bottom: 58px;
	}
	.contents {
		padding-left: pcw(200);
	}
	.summary {
		font-size: 14px;
		line-height: 1.9;
		margin-bottom: 49px;
	}
	.subhead {
		font-weight: bold;
		font-size: 16px;
		line-height: 1.9;
		margin-bottom: 10px;
	}
	.item-box {
		border-bottom: 1px solid $gray4;
		margin-bottom: 32px;
		.summary {
			margin-bottom: 20px;
		}
	}
	.mod-basic-btn {
		margin-top: 64px;
		display: flex;
		justify-content: flex-end;
	}
}



h2.interviewMain{
	@include setPcContents();
	margin-top: 75px;
	margin-bottom: 142px;
	img{
		width: 100%;
	}
}

.interview-sec-contents {
	@include setPcContents();
	margin-top: 75px;
	margin-bottom: 142px;
	.contents {
		padding-left: pcw(200);
	}
	.section-title {
		margin-bottom: 52px;
		color: #34426A;
	}
	.heading {
		color: $ml_blue;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.9;
		text-align: center;
		margin-bottom: 60px;
	}
	.subhead {
		font-weight: bold;
		font-size: 19px;
		line-height: 1.8;
		margin-bottom: 34px;
	}
	.pic {
		img {
			width: 100%;
			height: auto;
		}
	}
	.note {
		font-size: 13px;
		line-height: 24px;
		margin-bottom: 42px;
	}
	.stepup {
		padding-top: 54px;
		position: relative;
		margin: 80px 12px 0px;
		padding: 45px 40px 35px 40px;
		background: $gray5;
		border: 1px solid $gray4;
		&-title {
			position: absolute;
			top: -50px;
			left: 40px;
			text-align: left;
			display: inline-block;
			color: $ml_sky;
			font-weight: 700;
			line-height: 80px;
			.num {
				font-size: 52px;
				padding: 0px 4px;
			}
			.text {
				font-size: 24px;
			}
		}
		p{
			font-size: 13px;
			line-height: 24px;
		}
		.withImg{
			display: flex;
			gap:32px;
			justify-content: flex-end;
		    -webkit-flex-direction: row-reverse;
		    flex-direction: row-reverse;
			img{
				width: 216px;
				height: auto;
			}
		}
	}
	.dayoff{
		padding-top: 40px;
	}
	.comment{
		display: flex;
		align-items: center;
		gap: 48px;
		img{
			width: 360px;
		}
		p.note{
			margin: 0;
		}
	}
	
	.back-button{
		text-align: center;
		a{
			margin: 0 auto;
			width: 320px;
		}
	}
}


.benefit-nav{
	@include setPcContents();
	margin-top: 75px;
	margin-bottom: 32px;
	text-align: right;
	padding-bottom: 24px;
	border-bottom: 1px solid $gray4;
	li{
		display: inline-block;
		padding-left: 16px;
		font-size: 12px;
		a{
			color: $ml_blue;
			text-decoration: none;
			background-image: url(../../assets/img/common/icon-arrow-bottom.svg);
			background-position: right center;
			background-repeat: no-repeat;
			background-size:8px 8px;
			padding-right: 12px;
			&:hover{
				text-decoration: underline;
			}
		}	
	}
}
.benefit-sec-contents {
	@include setPcContents();
	margin-top: 50px;
	margin-bottom: 40px;
	.contents {
		padding-left: pcw(200);
	}
	.benefit-title {
		color: $ml_blue;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.9;
		text-align: left;
		margin-bottom: 40px;
		display: flex;
		height: 48px;
		overflow: hidden;
		gap: 24px;
		&.fukuri{
			margin-bottom: 24px;
		}
		span.icon{
			display: block;
			img{
				width: 65px;
				height: 48px;
			}
		}
		span.fukuri{
			display: block;
			img{
				width: auto;
				height: 48px;
			}
		}
		span.text{
			display: block;
			line-height: 52px;
		}
	}
	.benefit-subtitle {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 40px;
		padding-top: 30px;
		&:first-child{
			padding-top: 0;
		}
	}
	
	
	.benefit-contents{
		display: flex;
		flex-wrap: wrap;
		gap: 4%;
		.block{
			width: 48%;
			padding-bottom: 12px;
			border-bottom: 1px solid $gray4;
			margin-bottom: 24px;
			h3,h4{
				font-size: 15px;
				font-weight: bold;
				margin-bottom: 12px;
			}
			p{
				font-size: 13px;
				line-height: 1.9;
			}
		}
		.single-lead{
			font-size: 13px;
			line-height: 1.9;
			margin-bottom: 15px;
			a{
				color: #005b98;
				text-decoration: underline;
			}
		}
		.fukuri-link{
			display: flex;
			gap:16px;
			font-size: 13px;
			align-items: center;
			
			img{
				height: 40px;
			}
			a{
				color: #005b98;
				text-decoration: underline;
			}
		}
	}
	.caption{
		margin-top: 20px;
		text-align: center;
		font-size: 13px;
		padding: 16px;
		background-color: $gray5;
		border: 1px solid $gray4;
		margin-bottom: 80px;
	}
	.caption.kenko{
		display: flex;
		align-items: center;
		margin-top: 80px;
		margin-bottom: 80px;
		gap: 24px;
		padding: 28px 32px 28px 24px;
		p{
			text-align: left;
			font-size: 13px;
			line-height: 1.9;
		}
		p.img{
			width: 180px;
			img{
				width: 180px;
			}
		}
	}
	
	
}
