// 共通ページタイトル PC
.mod-page__nav {
	@include setPcContents();
	margin-top: 56px;
	margin-bottom: 70px;
	.contents {
		display: flex;
		align-items: center;
	}
	.label {
		flex-shrink: 0;
		font-size: 16px;
		line-height: 2;
		margin-right: 30px;
		pointer-events: none;
		font-weight: bold;
	}
	.list {
		display: flex;
		margin-right: -20px;
	}
	.item {
		margin-right: 20px;
		font-size: 13px;
	}
	.link {
		position: relative;
		color: $gray3;
		font-size: 13px;
		line-height: 1.65;
		display: flex;
		padding-right: 11px;
		transition: color 0.6s $easeOutExpo;
		&.current {
			color: $ml_blue;
			&::before {
				background: $ml_blue;
				transform: scaleX(1);
			}
			&::after {
				border-left-color: $ml_blue;
			}
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 100%;
			height: 1px;
			background: $gray4;
			transform: scaleX(0);
			transform-origin: right top;
			transition: transform 0.8s $easeOutExpo;
		}

		&::after {
			content: "";
			position: absolute;
			right: -2px;
			bottom: 0;	
			border: 3px solid transparent;
			border-left-color: $gray4;
			transform: rotate(45deg);
		}

		&:hover {
			color: $ml_blue;
			&::after {
				border-left-color: $ml_blue;
			}
			&::before {
				background: $ml_blue;
				transform: scaleX(1);
				transform-origin: left top;
			}
		}
	}
}
