// 採用ページ SP

.recruit-page {
	position: relative;
	z-index: $zIndex2;
	//padding-top: 64px;
	overflow: hidden;
	margin-bottom: -1px;
	background: $white;

	.lead-text {
		font-size: 13px;
		line-height: 26px;
		margin-left: 35px;
		margin-right: 35px;
		margin-bottom: 75px;
	}

	.section-title {
		font-weight: bold;
		font-size: 24px;
		line-height: 1.5;
		border-bottom: 1px solid $gray4;
		padding-bottom: 13px;
	}
}

aside.recruit__nav {
	.link {
		position: relative;
		display: block;
		img {
			width: 100%;
			height: auto;
		}
		.text {
			color: $white;
			font-weight: bold;
			font-size: 16px;
			line-height: 32px;
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

/**
 * 採用トップページ
 */
 .recruit-insta-btn {
	margin: -48px 35px 56px;
	text-align: right;
	a{
		width: auto;
		font-size: 12px;
		line-height: 20px;
	}
}
 
 
.recruit-message-section {
	margin: 30px 35px 44px;
	.title {
		font-weight: bold;
		font-size: 24px;
		line-height: 46px;
		margin-bottom: 23px;
	}
	.message {
		font-size: 13px;
		line-height: 26px;
		margin-bottom: 14px;
	}
	.by {
		span {
			display: block;
			text-align: right;
		}
		.label {
			font-size: 11px;
			line-height: 22px;
			margin-bottom: 2px;
		}
		.text {
			font-size: 18px;
			line-height: 32px;
		}
	}
}

.recruit-movie {
	margin: 0 35px 110px;
	&__contents {
		position: relative;
		margin: auto;
	}
	.thumb {
		width: 100%;
		height: auto;
	}
	
	@include moviePlay(0.75, false);

	.youtube {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 1;
		overflow: hidden;
		&-contents {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.recruitment-section {
	margin: 0 35px 110px;
	.section-title {
		margin-bottom: 48px;
	}
}

.recruit-topics-section {
	@include spNewsList();
	margin: 0 35px 50px;
	.section-title {
		margin-bottom: 48px;
	}
	
	.mod-basic-btn {
		margin-top: 48px;
		a {
			margin: auto;
		}
	}
}
.recruit-interview-section {
	@include spNewsList();
	margin: 0 35px 60px;
	.section-title {
		padding-top: 60px;
		margin-bottom: 48px;
	}
	aside.recruit__interview {
		.link {
			position: relative;
			display: block;
			img {
				width: 100%;
				height: auto;
			}
			.text {
				font-size: 14px;
				padding: 12px 0 32px 0;
			}
		}
	}
	
}
.recruit-data-section {
	margin: 0 35px 110px;
	.graph {
		img {
			width: 100%;
			height: auto;
		}
	}
}

.other-section {
	margin: 0 35px 110px;

	.section-title {
		margin-bottom: 30px;
	}
	.summary {
		font-size: 13px;
		line-height: 26px;
		margin-bottom: 33px;
	}
	.subhead {
		font-weight: bold;
		font-size: 15px;
		line-height: 30px;
		margin-bottom: 10px;
	}
	.item-box {
		border-bottom: 1px solid $gray4;
		margin-bottom: 32px;
		.summary {
			margin-bottom: 20px;
		}
	}

}


h2.interviewMain{
	margin: 46px 0 70px;
	img{
		width: 100%;
	}
}
.interview-sec-contents {
	margin: 46px 35px 100px;
	.section-title {
		margin-bottom: 40px;
		color: #34426A;
	}
	.heading {
		color: $ml_blue;
		font-weight: bold;
		font-size: 19px;
		line-height: 1.8;
		margin-bottom: 55px;
	}
	.subhead {
		font-weight: bold;
		font-size: 16px;
		line-height: 1.6;
		margin-bottom: 20px;
	}
	.pic {
		img {
			width: 100%;
			height: auto;
		}
		&.table {
			margin-left: -35px;
			margin-right: -35px;
			overflow-x: auto;
		}
		.table__scroll {
			width: 700px;
			padding: 0 35px 35px;
		}
	}
	.note {
		font-size: 13px;
		line-height: 26px;
		margin-bottom: 32px;
	}
	.stepup {
		padding-top: 54px;
		position: relative;
		margin: 60px 0 40px;
		padding: 35px 30px 35px 30px;
		background: $gray5;
		border: 1px solid $gray4;
		&-title {
			position: absolute;
			top: -44px;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			display: inline-block;
			color: $ml_sky;
			font-weight: 700;
			line-height: 80px;
			width: 100%;
			.num {
				font-size: 33px;
				padding: 0px 4px;
			}
			.text {
				font-size: 20px;
			}
		}
		p{
			font-size: 13px;
			line-height: 24px;
		}
		.withImg{
			img{
				width: 100%;
				height: auto;
				margin-bottom: 20px;
			}
		}
	}
	.dayoff{
		padding-top: 40px;
	}
	.comment{
		img{
			width: 100%;
			margin-bottom: 24px;
		}
		p.note{
			margin: 0;
		}
	}
	.back-button{
		text-align: center;
		a{
			margin: 0 auto;
			width: 320px;
		}
	}
}

.benefit-nav{
	margin: 0px 35px 80px;
	text-align: left;
	padding-bottom: 20px;
	border-bottom: 1px solid $gray4;
	li{
		display: inline-block;
		padding-right: 18px;
		padding-bottom: 16px;
		font-size: 13px;
		a{
			color: $ml_blue;
			text-decoration: none;
			background-image: url(../../assets/img/common/icon-arrow-bottom.svg);
			background-position: right center;
			background-repeat: no-repeat;
			background-size:8px 8px;
			padding-right: 12px;
			&:hover{
				text-decoration: underline;
			}
		}	
	}
}
.benefit-sec-contents {
	margin: 20px 35px 60px;
	.contents {
		padding-left: pcw(200);
	}
	.benefit-title {
		color: $ml_blue;
		font-weight: bold;
		font-size: 24px;
		line-height: 1.9;
		text-align: left;
		margin-bottom: 40px;
		display: flex;
		height: 60px;
		overflow: hidden;
		gap: 20px;
		&.fukuri{
			margin-bottom: 16px;
		}
		span.icon{
			display: block;
			img{
				width: 80px;
				height: 60px;
			}
		}
		span.fukuri{
			display: block;
			img{
				width: auto;
				height: 48px;
			}
		}
		span.text{
			display: block;
			line-height: 68px;	
		}
	}
	.benefit-subtitle {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 40px;
		padding-top: 30px;
		&:first-child{
			padding-top: 0;
		}
	}
	
	
	.benefit-contents{

		.block{
			width: 100%;
			padding-bottom: 16px;
			border-bottom: 1px solid $gray4;
			margin-bottom: 30px;
			h3,h4{
				font-size: 15px;
				font-weight: bold;
				margin-bottom: 12px;
			}
			p{
				font-size: 13px;
				line-height: 1.9;
			}
		}
		.single-lead{
			font-size: 13px;
			line-height: 1.9;
			a{
				color: #005b98;
				text-decoration: underline;
			}
		}
		.fukuri-link{
			//display: flex;
			//gap:16px;
			//align-items: center;
			font-size: 13px;
			padding-top: 16px;
			
			img{
				height: 40px;
				margin-bottom: 15px;
			}
			a{
				padding-left: 4px;
				color: #005b98;
				text-decoration: underline;
			}
		}
	}
	.caption{
		margin-top: 20px;
		text-align: center;
		font-size: 13px;
		line-height: 1.7;
		padding: 16px;
		background-color: $gray5;
		border: 1px solid $gray4;
		margin-bottom: 80px;
	}
	.caption.kenko{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 24px;
		padding: 20px 24px 20px 24px;
		margin-top: 80px;
		p{
			text-align: left;
			font-size: 13px;
			line-height: 1.9;
			width: 100%;
		}
		p.img{
			width: 100%;
			text-align: center;
			padding-left: 8px;
			img{
				width: 180px;
			}
		}
	}
	
	
}

