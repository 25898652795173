// 共通フッター PC

.site-footer {
	position: relative;
	z-index: $zIndex1;
	background: $black;
	padding: 175px 20px 20px;
	&.min{
		padding-top: 75px;
	}

	&__contents {
		max-width: 1130px;
		margin: auto;
	}
	&__upper {
		display: flex;
		margin-bottom: 70px;
	}
	&__block {
		margin-right: 125px;
		&:last-child {
			margin-right: 0;
		}
	}
	&__logo {
		margin-bottom: 42px;
		a {
			transition: opacity 2s $easeOutCubic;
			&:hover {
				opacity: 0.6;
				transition-duration: 0.3s;
			}
		}
	}

	.sns {
		&-list {
			display: flex;
		}
		&-item {
			margin-right: 12px;
		}
		&-link {
			transition: opacity 2s $easeOutCubic;
			&:hover {
				opacity: 0.6;
				transition-duration: 0.3s;
			}
		}
	}

	.site-menu {
		display: flex;
		&__block {
			min-width: 112px;
			margin-left: 83px;
			padding-top: 3px;
			&:first-child {
				margin-left: 0;
			}	
		}
		&__title {
			margin-bottom: 5px;
			a,
			.no-link {
				color: $white;
				font-size: 14px;
				line-height: 28px;
				font-weight: 700;
				&.toggle {
					pointer-events: none;
					&:hover {
						opacity: 1;
					}
				}
			}
			a {
				transition: opacity 2s $easeOutCubic;
				&:hover {
					opacity: 0.6;
					transition-duration: 0.3s;
				}
			}
		}
		&__list {
			margin-left: -1px;
			margin-bottom: 3px;
		}
		&__link,
		&__no-link {
			color: $gray3;
			font-size: 12px;
			line-height: 26px;
		}
		&__link {
			transition: opacity 2s $easeOutCubic;
			&:hover {
				opacity: 0.6;
				transition-duration: 0.3s;
			}
		}
	}
	
	.client-menu {
		display: flex;
		margin-top: 36px;
		.mod-basic-btn {
			margin-right: 15px;
		}
	}

	.policy-menu {
		&__list {
			display: flex;
		}
		&__item {
			margin-right: 20px;
		}
		&__link {
			color: $gray2;
			font-size: 11px;
			line-height: 22px;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.copyright {
		position: absolute;
		right: 30px;
		bottom: 25px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		a {
			transition: opacity 2s $easeOutCubic;
			&:hover {
				opacity: 0.6;
				transition-duration: 0.3s;
			}
		}
		small {
			margin-top: 18px;
		}
	}
}

.page-top-scroll {
	position: absolute;
	right: -15px;
	bottom: 500px;
	z-index: ($zIndex1 + 4);
	&.min{
		bottom: 400px;
	}
	@media screen and (max-width: 1030px) {
		right: -30px;
		bottom: 590px;
	}
	a {
		color: $white;
		font-size: 12px;
		letter-spacing: 0.1em;
		display: block;
		padding: 10px;
		padding-left: 70px;
		transform: rotate(90deg);
	}
	.border {
		position: absolute;
		bottom: 1px;
		left: 0;
		width: calc(100%-10px);
		height: 1px;
		background: $white;
		transform: scaleX(1);
	}
}

@media screen and (max-width: 1030px) {
	.site-footer {
		padding-top: 100px;
	
		&__upper {
			flex-direction: column;
			margin-bottom: 0;
		}
		&__block {
			margin-right: 0;
			margin-bottom: 60px;
		}
		&__logo,
		&__sns {
			display: flex;
			justify-content: center;
		}
	
		.site-menu,
		.client-menu {
			justify-content: center;
		}
	}
}