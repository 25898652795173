// 個人情報保護方針 SP

.policy-page {
	position: relative;
	z-index: $zIndex2;
	padding-top: 64px;
	margin-bottom: -1px;
	overflow: hidden;
}

.policy__group {
	margin-bottom: 105px;
}

.policy__contents {
	padding: 0 35px;

	.lead-text {
		color: $gray1;
		font-size: 15px;
		line-height: 30px;
		margin-bottom: 50px;
	}

	.section {
		margin-top: -1px;
		border-top: 1px solid $gray4;
		border-bottom: 1px solid $gray4;
		padding-top: 40px;
		padding-bottom: 20px;
		&.bottom-none {
			border-bottom: none;
		}

		.title {
			font-weight: bold;
			font-size: 15px;
			line-height: 30px;
			margin-bottom: 10px;
		}

		.summary,
		.notes,
		.list {
			font-size: 13px;
			line-height: 26px;
			margin-top: 6px;
		}

		.summary {
			padding-left: 14px;
		}

		.list {
			margin-top: 6px;
			text-indent: -14px;
			padding-left: 14px;
		}
	}
	.update-date-at {
		text-align: right;
		font-size: 13px;
		line-height: 26px;
	}
	.support {
		margin-top: 48px;
		.info {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $gray4;
			box-shadow: none;
		}
	}
}