// 共通ボタン SP

.mod-basic-btn {
	a,
	button {
		font-size: 16px;
		line-height: 30px;
		width: 100%;
		height: 56px;
		max-width: 305px;
		&.flex {
			max-width: 305px;
			width: 100%;
		}
	}

	&.small {
		a,
		button {
			font-size: 11px;
			line-height: 18px;
			width: 200px;
			height: 42px;
		}
	}
}