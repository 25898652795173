// 共通フッター

.site-footer-wrapper {
	overflow: hidden;
	position: relative;
	z-index: $zIndex1;
}

@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}

@import "./animate";