// エラー PC

.error-page {
	position: relative;
	z-index: $zIndex2;
	padding-top: pcw(90);
	padding-bottom: pcw(110);
	margin-bottom: -1px;
	overflow: hidden;
	text-align: center;
	
	h1{
		@include setPcFont(32, 80);
		font-weight: 700;
		margin-bottom: 15px;
	}
}


.error__contents {
	@include setPcContents();

	p{
		font-size: 13px;
		line-height: 1.9;
		margin-bottom: 50px;
	}
	div{
		width: 264px;
		margin: 0px auto;
	}
}