// 共通タイトル SP

.mod-title {
	&__en {
		color: $black;
		@include setSpFont(11, 22);
		font-weight: 700;
		letter-spacing: 0.02em;
		padding-bottom: 1px;
		border-bottom: 1px solid $black;

		&.white {
			color: $white;
			border-bottom-color: $white;
		}
	}

	&__ja {
		@include setPcFont(36, 48);
		font-weight: 700;
		
		
		&.white {
			color: $white;
		}
	}
}