// 共通 採用情報

.footer-recruit {
	.js-scroll-img {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		&-inner {
			transform: scale(1.15);
			background: url(../img/common/footer_recruit_bg.jpg) no-repeat 50% 50%;
			background-size:  cover;
		}
	}
}
@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
