// トップページ PC

.top-mv {
	.message {
		position: absolute;
		z-index: 2;
		bottom: 2%;
		left: 9%;
		// width: getWidth(480);
		width: 40%;
		span {
			display: block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
	@include pcNewsList();
	.news-item {
		position: absolute;
		z-index: 2;
		bottom: 5%;
		right: 4%;
		margin-bottom: 0;
		min-width: 360px;
		@media screen and (max-width: $pcDesignWidth2 * 1px) {
			display: none;
		}
		.tag {
			background: $white;
			color:  $black;
		}
		.date {
			color: $white;
		}
		.title {
			color: $white;
			max-width: 410px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		a {
			&::after {
				border-left-color: $white;
			}
			&::before {
				background: $white;
			}
		}
	}
	.scroll {
		position: absolute;
		z-index: 2;
		right: 20px;
		top: 60%;
		a {
			color: $white;
			font-size: 12px;
			letter-spacing: 0.1em;
			display: block;
			padding: 10px;
			transform: rotate(90deg);
		}
		.border {
			position: absolute;
			bottom: 0;
			left: 10px;
			width: 60px;
			height: 1px;
			background: $white;
			transform: scaleX(0);
		}
	}
}

// 
// MAINLANDについて
// 
.top-about {
	position: relative;
	z-index: $zIndex1;
	padding-top: 463px;
	margin-bottom: -30vw;
	@media screen and (min-width: 1540px) {
		margin-bottom: -20vw;
	}
	.message {
		color: $white;
		font-size: 16px;
		line-height: 3.2;
		font-weight: 700;
		text-align: center;
		letter-spacing: 0.04em;
		margin-bottom: 0;
	}
	.movie {
		position: relative;
		display: flex;
		align-items: center;
		padding: 40vw 0;
		min-height: 1000px;
		&__contents {
			position: relative;
			max-width: 966px;
			padding: 0 20px;
			margin: auto;
		}
		.thumb {
			width: 100%;
			height: auto;
		}

		@include moviePlay(1, true);
		
		.youtube {
			position: absolute;
			left: 20px;
			right: 20px;
			bottom: 0;
			top: 0;
			z-index: 1;
			overflow: hidden;
			&-contents {
				// position: absolute;
				// left: 0;
				// top: 0;
				width: 100%;
				height: 0;
				padding-bottom: 56.25%;
			}
			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}



.top-contents-wrapper {
	position: relative;
	z-index: $zIndex1+1;
	&::after {
		content: "";
		z-index: $zIndex1 - 1;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 50px;
		background: $white;
	}
}
// 
// Property
// 
.top-property {
	position: relative;
	z-index: $zIndex2;
	padding-top: 77px;
	margin-bottom: 178px;
	&__contents {
		@include setPcContents();
		margin-bottom: 113px;
	}
	.mod-title__en  {
		margin-bottom: 75px;
	}

	.mod-title__ja {
		margin-bottom: 24px;
	}

	&__description {
		font-size: 15px;
		line-height: 30px;
	}
	
	&__item {
		display: flex;
		width: pcw(1060);
		margin-bottom: 125px;

		.thumb {
			width: pcw(640);
			@include pcSmall {
				width: pcw(600);
			}
			// img {
			// 	width: 100%;
			// 	height: auto;
			// }
		}

		.contents {
			position: relative;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 60px;
			padding-top: 20px;
			@include pcSmall {
				padding-left: 40px;
			}
		}

		.num {
			position: absolute;
			left: pcw(-40);
			top: pcw(-60);
			width: pcw(130);
			@include pcSmall {
				top: pcw(-80);
			}
			img {
				width: 100%;
				height: auto;
			}
		}

		.title {
			color: $ml_blue;
			font-size: pcw(24);
			line-height: pcw(48);
			font-weight: 700;
			letter-spacing: 0.02em;
			margin-bottom: 20px;

			@include setPcLargeFont(24, 48);
		}

		.summary {
			font-size: 14px;
			line-height: 1.9;
			margin-bottom: 33px;
		}

		
		&.type2 {
			margin-left: pcw(223);
		}
	}
}

// 
// 物件情報
// 
.top-property-list {
	position: relative;
	z-index: $zIndex2;
	margin-bottom: 136px;

	&__contents {
		@include setPcContents();
	}

	&__title {
		font-weight: 700;
		font-size: pcw(32);
		line-height: 180%;
		padding-bottom: 28px;
		border-bottom: 1px solid #EBEBEB;
		margin-bottom: 56px;
	}

	&__item-list {
		margin-left: calc(20% - 24px);
		.description {
			font-weight: 400;
			font-size: 13px;
			line-height: 190%;
			margin-left: 24px;
			margin-bottom: 64px;
		}
	}

	.mod-basic-btn{ 
		display: flex;
		justify-content: flex-end;
		&.is_center {
			justify-content: center;
		}
	}
}

// 
// ニュース / イベント
// 
.top-news {
	position: relative;
	z-index: $zIndex2;
	margin-bottom: 147px;
	&__contents {
		@include setPcContents();
	}

	.mod-title__en {
		margin-bottom: 75px;
	}
	.mod-title__ja {
		line-height: 1.1;
		margin-bottom: 45px;
		// &.small {
		// 	@include setPcFont(42, 80);
		// }
	}

	&__info {
		display: flex;
		justify-content: space-between;
	}
	&__meta {
		width: 52%;
	}
	&__list {
		flex: 1;
	}

	.news-categories {
		padding-left: 5px;
		margin-bottom: 60px;
		li{
			display: block;
			margin-bottom: 15px;
			a {
				position: relative;
				color: $gray3;
				font-size: 16px;
				line-height: 20px;

				&::after {
					content: "";
					position: absolute;
					left: 0;
					bottom: -3px;
					width: 100%;
					height: 1px;
					background: $ml_blue;
					transform: scaleX(0);
					transform-origin: right top;
					transition: transform 0.8s $easeOutExpo;
				}
				&:hover {
					color: $ml_blue;
					&::after {
						transform: scaleX(1);
						transform-origin: left top;
					}
				}
				&.current {
					color: $ml_blue;
				}
			}
		}
	}
}

.top-news {
	@include pcNewsList();
}



