// 共通ページタイトル PC
.mod-page__title {
	display: flex;
	flex-direction: column;
	@include setPcContents();
	margin-bottom: 50px;
	.ja {
		font-size: pcw(54);
		line-height: 1.70;
		font-weight: 700;
		margin-bottom: 15px;
	}
	.en {
		font-size: pcw(14);
		font-weight: 700;
		line-height: 2;
	}
}
