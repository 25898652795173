// 物件リスト PC

.mod-property {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.mod-property-item {
	position: relative;
	width: calc(33.33% - 24px);
	margin-left: 24px;
	margin-bottom: 64px;
	.link {
		position: relative;
		height: 100%;
		display: block;
		padding-bottom: 16px;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -3px;
			width: 100%;
			height: 1px;
			background: $black;
			transform: scaleX(0);
			transform-origin: right top;
			transition: transform 0.8s $easeOutExpo;
		}

		&::after {
			content: "";
			position: absolute;
			right: -2px;
			bottom: -2px;	
			border: 6px solid transparent;
			border-left-color: $black;
			transform: rotate(45deg);
		}
		&:hover {
			&::before {
				transform: scaleX(1);
				transform-origin: left top;
			}
		}
	}
	.pic {
		position: relative;
		margin-bottom: 16px;
		.image {
			position: relative;
			width: 100%;
			aspect-ratio: 2045 / 1414;
			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.label {
			color: $white;
			font-weight: 400;
			font-size: 12px;
			line-height: 140%;
			letter-spacing: 0.04em;
			position: absolute;
			width: 68px;
			height: 20px;
			left: 0px;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $ml_sky;
		}
	}
	.name {
		font-weight: 700 !important;
		font-size: 18px !important;
		line-height: 160%;
		letter-spacing: 0.02em;
		margin-bottom: 12px;
	}
	.info,
	.spec,
	.area {
		font-weight: 400;
		font-size: 11px;
		line-height: 160%;
	}
	.info,
	.spec {
		margin-bottom: 3px;
	}
	.spec,
	.area {
		display: flex;
		.label {
			flex-shrink: 0;
		}
	}
}