// 共通 企業情報 SP

.footer-company {
	.top-page & {
		margin-bottom: -42px;
	}
	&__contents {
		@include setSpContents();
		margin-bottom: 73px;
	}
	.mod-title__en  {
		margin-bottom: 70px;
	}

	.mod-title__ja {
		margin-bottom: 30px;
	}

	&__description {
		font-size: 15px;
		line-height: 30px;
	}
	&__links {
		@include setSpContents();
		
		.item {
			width: 100%;
			margin-bottom: 5px;
		}
		a {
			position: relative;
			display: block;
		}
		.img {
			position: relative;
			z-index: 1;
			img {
				width: 100%;
				height: auto;
			}
		}
		.info {
			position: absolute;
			z-index: 2;
			left: 20px;
			top: 9px;
			display: flex;
			align-items: flex-end;
		}

		.ja,
		.en {
			display: block;
			color: $white;
			letter-spacing: 0.02em;
			font-weight: 700;
		}

		.ja {
			@include setSpFont(16, 32);
			margin-right: 10px;
		}

		.en {
			@include setSpFont(11, 22);
			padding-bottom: 3px;
		}
		
	}
}