// 共通 お問い合わせ PC
.footer-contact {
	position: relative;
	z-index: ($zIndex1 + 1);
	padding: 108px 0 0;
	margin-bottom: -64px;

	&__contents {
		@include setPcContents();
	}

	.mod-title__en  {
		margin-bottom: 75px;
	}

	.mod-title__ja  {
		margin-bottom: 21px;
	}

	&__description {
		font-size: 15px;
		line-height: 30px;
		width: 500px;
		margin-bottom: 58px;
	}

	&__list {
		display: flex;
		justify-content: center;
		margin: 0 -3px;
	}
	&__item {
		width: calc(33.333% - 6px);
		max-width: 308px;
		margin: 0 3px;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	}
	&__link,
	&__no-link {
		position: relative;
		display: flex;
		width: 100%;
		height: 160px;
		justify-content: center;
		align-items: center;
		background: $white;
		overflow: hidden;
	}
	&__link {
		&::before {
			content: "";
			content: "";
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $ml_blue;
			opacity: 1;
			transform: scaleX(0);
			transform-origin: right top;
			transition: transform 0.8s $easeOutExpo;
		}
		
		&::after {
			content: "";
			position: absolute;
			z-index: 3;
			right: -2px;
			bottom: -2px;
			border: 6px solid transparent;
			border-left-color: $black;
			transform: rotate(45deg);
			transition: border-left-color 0.3s 0.1s;
		}

		&:hover {
			color: $white;
			path {
				stroke: $white;
			}
			&::before {
				transform: scaleX(1);
				transform-origin: left top;
			}
			&::after {
				border-left-color: $white;
				transition: border-left-color 0.3s 0.1s;
			}
		}
	}
	&__link,
	&__no-link {
		.inner {
			position: relative;
			z-index: 2;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
		}
		.icon {
			display: block;
			width: 42px;
			height: 42px;
			margin-bottom: 8px;
		}
		.title {
			font-size: 16px;
			line-height: 32px;
			font-weight: 700;
		}

		.customer-service {
			.title {
				font-size: 13px;
				line-height: 26px;
				font-weight: 700;
				margin-bottom: -10px;
			}

			.tel {
				font-size: 28px;
				line-height: 56px;
				margin-bottom: -5px;
			}
			
			.notes {
				text-align: center;
				font-size: 11px;
				line-height: 18px;
				width: 100%;
			}
		}
	}
}
