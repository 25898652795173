 // 共通ページタイトル SP
.mod-page__title {
	display: flex;
	flex-direction: column;
	margin: 0 35px 60px;
	.ja {
		font-size: 38px;
		line-height: 1.4;
		font-weight: 700;
		margin-bottom: 6px;
	}
	.en {
		font-size: 14px;
		font-weight: 700;
		line-height: 1.85;
	}
}


