// プランモーダル
body.js-modal-open {
	overflow: hidden;
}

@include pc {
	@import "./pc";
}

@include sp {
	@import "./sp";
}
