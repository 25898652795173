* {
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
}

a {
	color: $black;
	-webkit-tap-highlight-color: transparent;
	&:link,
	&:visited {
		text-decoration: none;
	}
}

body {
	position: relative;
	font-family: Helvetica, 'Noto Sans JP', "游ゴシック", YuGothic, Arial, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, verdana, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color: $black;
	font-weight: 400;
	width: 100%;
	background: #fff;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	&::after {
		content: "";
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1;
		background: $white;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}
.page-wrapper {
	position: relative;
	z-index: 2;
}

@include pc {
	.sp-view {
		display: none !important;
	}
}

@include sp {
	.pc-view {
		display: none !important;
	}
}